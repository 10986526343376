import React, {useState} from 'react'

type CheckOrderItemProps = {
    order_qty: number,
    food_name: string,
    amount: number,
    addPayment: (amt: number) => void,
    minusPayment: (amt: number) => void
}

function CheckOrderItem({order_qty, food_name, amount, addPayment, minusPayment}: CheckOrderItemProps) {
    const itemUnitPrice = amount/ order_qty;
    const [adjustedQty, setAdjustedQty] = useState(order_qty);
    const [adjustedPrice, setAdjustedPrice] = useState(itemUnitPrice * adjustedQty);
    
    const handleAddQty = () => {
        const newQty = adjustedQty + 1;
        setAdjustedQty(newQty)
        setAdjustedPrice(newQty * itemUnitPrice)
        addPayment(itemUnitPrice)
    }

    const handleMinusQty = () => {
        if(adjustedQty > 0){
            const newQty = adjustedQty - 1;
            setAdjustedQty(newQty)
            setAdjustedPrice(newQty * itemUnitPrice)
            minusPayment(itemUnitPrice)
        }
    }


    return (
    <>
        <div className='admin-check-order-item-container'>
            <div className='admin-check-order-item-name'>{food_name}</div>
            <div className='admin-check-order-item-qty-price-container'>
                <div>${adjustedPrice}</div>

                <div className='admin-food-qty-container'>
                    <div className="admin-food-qty-minus-button" onClick={handleMinusQty}>-</div>
                    <div className="food-qty">{adjustedQty}</div>
                    <div className='admin-food-qty-add-button' onClick={handleAddQty}>+</div>
                </div>
            </div>
            
        </div>

        
    </>
  )
}

export default React.memo(CheckOrderItem)