import React from "react";
import TakeawayToCompleteButton from "./TakeawayToCompleteButton";
import TakeawayToPickupButton from "./TakeawayToPickupButton";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../adminStyle.scss";

type CustomizedTakeawayOrdersItemProps = {
    id: number,
    orderStatus: string,
    contactPhone: string,
    uuid: string
}

function AdminTakeawayItem({
  id,
  orderStatus,
  contactPhone,
  uuid,
}: CustomizedTakeawayOrdersItemProps) {
  return (
    <tr className="admin-takeaway-item-row">
      <td className="admin-takeaway-order-id">{id}</td>
      <td className="admin-takeaway-order-phone">{contactPhone}</td>
      <td className="admin-takeaway-order-status">
        {orderStatus === "COMPLETED"
          ? "Completed"
          : orderStatus === "PREPARING"
          ? "Preparing"
          : orderStatus === "PICKUP" && "Ready"}
      </td>
      <td className="admin-takeaway-status-container">
        {orderStatus === "COMPLETED" ? (
          ""
        ) : orderStatus === "PREPARING" ? (
          <TakeawayToCompleteButton id={id} />
        ) : (
          orderStatus === "PICKUP" && <TakeawayToPickupButton id={id} />
        )}
        {/* {order_status === "COMPLETED" && ""}
                        {order_status === "PENDING" && <TakeawayToCompleteButton id={id}/>}
                        {order_status === "PICKUP" && <TakeawayToPickupButton id={id}/>} */}
      </td>
    </tr>
  );
}

export default React.memo(AdminTakeawayItem);
