import React from 'react'
import { useAppSelector } from "../../app/hook";
import { IRootState } from '../../app/Store';
import FoodItem from './FoodItem';
import './foodStyle.scss'

type MenuByCategoryProps = {
    userChoice: string
}

interface menuItemsFromDb {
    id: number,
    foodName: string,
    imageUrl: string,
    unitPrice: number,
    stockStatus: boolean,
    foodTypeName: string, 
    quantity: number
}

function MenuByCategory({userChoice}: MenuByCategoryProps) {

    let initMenuList = (useAppSelector((state: IRootState) => state.menu.allItems)) as menuItemsFromDb[];
    let menuByType = initMenuList.filter((item) => item.foodTypeName === userChoice);


    return (
        <div className="food-list-container">
            {/* <div>{userChoice}</div> */}
            {menuByType.map((item)=> <FoodItem key={item.id} foodCategory={item.foodTypeName} id={item.id} 
                foodName={item.foodName} foodQty={item.quantity} imageUrl={item.imageUrl} unitPrice={item.unitPrice}/>)}
        </div>
    )
}

export default React.memo(MenuByCategory)