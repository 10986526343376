import React from 'react'
import DineInHome from './DineInHome'
import DineInError from './DineInError'
import { useAppSelector } from '../app/hook';
import { IRootState } from '../app/Store';


function DineInCheck() {
    let tableNum = useAppSelector((state: IRootState) => state.dineIn.tableNumber);
    let transactionNum = useAppSelector((state: IRootState) => state.dineIn.dineInTransactionNumber);
    
    return (
        <>
            {(!tableNum || !transactionNum) ? <DineInError/> : <DineInHome/>}
        
        </>
    )
}

export default DineInCheck