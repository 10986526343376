import React, {useState} from 'react'
import { Link } from 'react-router-dom';
import { set_customer_mode } from '../features/customerAuth/customerAuthSlice';
import { useAppDispatch } from '../app/hook';
import { reset_takeaway_state } from '../features/takeaway/takeawaySlice';
import { clear_user_dineIn_data } from '../features/dineIn/dineInSlice';

function ModeSwitch() {
    const [isShowMode, setIsShowMode] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleShowModeSwitchClick = () => {
        
        setIsShowMode(!isShowMode);
    }

    const handleModeClick = () => {
        setIsShowMode(false);
        dispatch(reset_takeaway_state());
        dispatch(clear_user_dineIn_data());
        dispatch(set_customer_mode(null));      
    }

    return (
        <>
            <div className='mode-floating-button'
                onClick={handleShowModeSwitchClick}>
                +
            </div>
            {isShowMode && 
                <div className='mode-switch-container'>
                    <div className='mode-title'>Mode</div>
                        <Link className="mode-link-item" 
                            style={{ textDecoration: 'none'}} 
                            onClick={handleModeClick}
                            to="/admin">Admin</Link>
                        <Link className="mode-link-item" 
                            onClick={handleModeClick}
                            style={{ textDecoration: 'none'}} to="/home">User</Link>
                </div>
            }
        </>
    )
}

export default React.memo(ModeSwitch)