import { useQuery } from "@tanstack/react-query";

type foodOrderItem = {
  id: number;
  foodName: string;
  foodQuantity: number;
  amount: number;
};

export async function submitTakeawayOrder(
  userInput: foodOrderItem[],
  takeawayUuid: string
) {
  if (userInput.length < 1 || !takeawayUuid) {
    return;
  }

  const orderListWithUuid = userInput.map((item) => {
    return {
      transactionsUuid: takeawayUuid,
      foodId: item.id,
      orderQty: item.foodQuantity,
      amount: item.amount,
    };
  });

  const res = await fetch(
    `${process.env.REACT_APP_BACKEND_API}/takeaway/order}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
      },
      body: JSON.stringify({ order: orderListWithUuid }),
    }
  );

  const result = await res.json();
  return result;
}

type CustomerTakeawayOrderRecord = {
  created: Date;
  order_status: string;
  total_amount: number;
  id: number;
};

export function useCustomerTakeawayOrderSummary() {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["customerTakeawayOrder"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/takeaway/order/history`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
          },
        }
      );

            let result = await res.json();

            return result as CustomerTakeawayOrderRecord[];
        }
    })

  if (isLoading || isFetching || error || !data) {
    return [];
  }

  return data;
}

type TakeawayOrderRecord = {
  transactionsUuid: string;
  foodId: number;
  orderQty: number;
  amount: number;
};

type CustomerOrder = {
  mini_orders_takeaway: TakeawayOrderRecord[];
  orders_takeaway: {
    payment_method: string;
    payment_status: string;
    total_amount: number;
    uuid: string;
    contact_name: string;
    contact_phone: string;
  };
};

export async function customerSubmitTakeawayOrder(
  input: CustomerOrder,
  newTransactionUuid: string
) {
  if (input.mini_orders_takeaway.length < 1) {
    return;
  }

  const transaction_uuid = newTransactionUuid;

  try {
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/takeaway/order/${transaction_uuid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
        },
        body: JSON.stringify(input),
      }
    );

    const result = await res.json();

    if (result) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export async function customerGetOrderSummary(customer_id: string) {
  try {
    const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/order`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
      },
    });
    const result = await res.json();
    return result;
  } catch (err) {
    return false;
  }
}
