import React, {useEffect} from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hook'
import { IRootState } from '../../app/Store'
import { save_order_summary } from '../dineIn/dineInSlice'
import './orderStyle.scss';
import { set_user_action } from '../dineIn/dineInSlice';
import ViewOrderItem from './ViewOrderItem';

interface OrderSummaryItemFromDb {
  id: number;
  foodName: string;
  order_qty: number;
  amount: number;
}

interface OrderSummaryItem {
    id: number;
    foodName: string;
    total_qty: number;
    total_amount: number;
}

function ViewOrder() {
    const dispatch = useAppDispatch();
    let transaction_num = useAppSelector((state: IRootState) => state.dineIn.dineInTransactionNumber);
    let table_num = useAppSelector((state: IRootState) => state.dineIn.tableNumber);
    let transaction_status = useAppSelector((state: IRootState) => state.dineIn.dineInStatus);

    useEffect(()=> {
        const fetchOrderSummary = async () => {
            try {
                if(transaction_num){
                    const backendPath = process.env.REACT_APP_BACKEND_API;
                    const res = await fetch(`${backendPath}/order/${transaction_num}`, {
                      headers: {
                        "Authorization":`Bearer ${localStorage.getItem('customer_token')}` 
                      }
                    });
                    const result = await res.json();
                    const orderSummaryFromDb = result;

                    let orderSummary = [...orderSummaryFromDb];

                    orderSummary = orderSummary.map(item => {
                      return {
                        id: item.foodId,
                        order_qty: item.orderQty,
                        foodName: item.foodName,
                        amount: item.amount
                      }
                    })

                    const summary = Object.values(
                        orderSummary.reduce((acc: { [key: string]: { id: number; foodName: string; total_qty: number; total_amount: number } }, item: OrderSummaryItemFromDb) => {
                          if (acc[item.id]) {
                            acc[item.id].total_qty += item.order_qty;
                            acc[item.id].total_amount += item.amount;
                          } else {
                            acc[item.id] = { ...item, total_qty: item.order_qty, total_amount: item.amount };
                          }
                          return acc;
                        }, {})
                      );
                    
                    dispatch(save_order_summary(summary as OrderSummaryItem[]));
                } else {
                    console.log(`View order summary error: invalid transaction number`)
                }
            } catch (err){
                console.log(`View order summary error: ${err}`)
            }
        }
        fetchOrderSummary()
    }, [dispatch, transaction_num])

  let orderSummaryList = useAppSelector((state: IRootState)=> state.dineIn.orderSummary);

  let dishQty = orderSummaryList.reduce((acc, curr) => {
    return acc + curr.total_qty
  }, 0)

  let orderSumAmount = orderSummaryList.reduce((acc, curr)=> {
    return acc + curr.total_amount
  },0)

  return (
    <div className="orderContainer">
        <div className='user-section-title'>my order</div>
        <div className='order-info-container'>
            <p>Dine-in</p>
            <p>Table Number <span>#{table_num}</span></p>
            <p>Status: {transaction_status}</p>
        </div>

        <div className='order-item-list-container'>
          {orderSummaryList?.map((item)=> 
          <ViewOrderItem key={item.id} id={item.id} food_name={item.foodName} 
            total_qty={item.total_qty} total_amount={item.total_amount}/>)}
        </div>

        <div className='orderSummaryContainer'>
          <p>Sub Total</p>

          <div className='total-amount-container'>
            <span>Total Amount:</span> 
            <span>${orderSumAmount}</span>
          </div>

          <div className='dish-number-container'>
            <span>Dish Number:</span>
            <span>{dishQty}</span>
          </div>
        </div>

        <div onClick={()=> {
          dispatch(set_user_action('home'))
        }} className='add-new-order-button'>Add New Order</div>

    </div>
  )
}

export default React.memo(ViewOrder)