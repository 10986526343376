import React from 'react'
import { IRootState } from '../../app/Store'
import { useAppSelector } from '../../app/hook'
import TakeawayFoodItem from './TakeawayFoodItem'
import './takeawayStyle.scss';

function TakeawayFoodList() {
    const initTakeawayMenuList = useAppSelector((state: IRootState) => state.menu.allItems)

    return (
        <div className='takeaway-food-list-container'>
            {initTakeawayMenuList.map((item)=> 
                <TakeawayFoodItem key={item.id} id={item.id} foodName={item.foodName} foodCategory={item.foodTypeName} 
                imageUrl={item.imageUrl} unitPrice={item.unitPrice} foodQty={item.quantity}/>
            )}
        </div>
    )
    }

export default React.memo(TakeawayFoodList)