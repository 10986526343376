import React, {useState} from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { IRootState } from '../../../app/Store';
// import { adminConfirmPaymentMethod } from '../adminAPI';
import { set_admin_current_action } from '../adminSlice';

// import ToggleButtonGroup from 'react-bootstrap/ButtonGroup';
// import ToggleButton from 'react-bootstrap/ToggleButton';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
// import PaymentMethod from './PaymentMethod';
// import PaymentMethod from './PaymentMethod';
// import { useMutation } from '@tanstack/react-query';

type CollectPaymentProps = {
    flowControl: (stage: string) => void
}

function CollectPayment({flowControl}: CollectPaymentProps) {
    const orderPayment = useAppSelector((state: IRootState) => state.admin.adminCurrentAction?.actionSaveAmount);
    const tableNumber = useAppSelector((state: IRootState) => (state.admin.adminCurrentAction?.actionTableNumber) as number);
    const transaction_num = useAppSelector((state: IRootState) => state.admin.adminCurrentAction?.actionTransactionNumber);
    const dispatch = useAppDispatch()
    const [paymentMethod, setPaymentMethod] = useState("CASH");
  
    const MySwal = withReactContent(Swal);

    const onSubmitOrderPayment = async() => {

        const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/admin/checkout2/${transaction_num}`, {
        method: "POST",
        headers: {
            "Content-Type":"application/json",
            "Authorization":`Bearer ${localStorage.getItem('adminToken')}`
        },
        body: JSON.stringify({
            paymentMethod: paymentMethod,
            tableNo: tableNumber,
        })
        });

        const result = await res.json();

        if(result){
            Swal.fire({
                icon: 'success',
                title: 'Payment completed',
                showConfirmButton: false,
                timer: 1500
            })
            dispatch(set_admin_current_action(null))
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to proceed payment',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    return (
        <div className='collect-payment-container'>  
            
            <div className='section-title'>dine-in proceed payment</div>

            {/* <div className='proceed-payment-table-num-container'>
               
            </div> */}

            <div className='order-summary-container'>
                <span className='order-summary-title'>Order Summary</span>
                 <div className='order-summary-table-number-container'>
                    <span>Table Number: </span>
                    <span>{tableNumber}</span>
                </div>

                 <div className='order-summary-table-total-amount-container'>
                    <span>Total Amount: </span> 
                    <span>${orderPayment}</span>
                </div>
            

                <div className='payment-method-main-container'>
                    <div>Payment Method</div>

                    <select value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)} id="paymentMethod" name="paymentMethod">
                        <option value="CASH">Cash</option>
                        <option value="CREDITCARD">Card</option>
                    </select>
                </div>


                <div className='button-container'>
                    <div className="back-button" onClick={()=> flowControl("checking")}>Back</div>
                    <div className="next-button" onClick={() => onSubmitOrderPayment()}>Confirm</div>
                </div>
            </div>
            
            
            
        </div>
        
    )
}

export default React.memo(CollectPayment)