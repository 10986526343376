import React, {useState} from 'react'
import LoginForm from '../customerAuth/LoginForm'
import RegisterForm from '../customerAuth/RegisterForm'
import '../../pages/loginPageStyle.scss';
import './takeawayStyle.scss';
import { set_takeaway_user_action } from './takeawaySlice';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { IRootState } from '../../app/Store';

function TakeawayLogin() {
    const dispatch = useAppDispatch();
    const userShoppingBagStatus = useAppSelector((state: IRootState) => state.takeawayStatus.takeawayOrder);
    const [userAction, setUserAction] = useState<"login"|"register"|"complete">("login");
    const handleChangeAction = (input: "login"|"register"|"complete") => {
      setUserAction(input);
    }

    const handleFormSubmitState = () => {

      setUserAction("complete");

      if(userShoppingBagStatus.length > 0) {
        dispatch(set_takeaway_user_action('paymentAndInfo'));
      } else {
        dispatch(set_takeaway_user_action('menu'))
      }


    }
  return (
    <div className='takeaway-login-page-container'>
        {userAction === "login" && <LoginForm formSubmitFn={handleFormSubmitState} changeActionFn={()=> handleChangeAction("register")}/>}
        {userAction === "register" && <RegisterForm formSubmitFn={handleFormSubmitState} changeActionFn={()=> handleChangeAction("login")}/>}
    </div>
  )
}

export default React.memo(TakeawayLogin)