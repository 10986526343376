import React from 'react'
import "./navbarStyle.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChampagneGlasses, faBookOpen, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { set_user_action } from '../features/dineIn/dineInSlice';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { IRootState } from '../app/Store';



function DineInNavBar() {
  const dispatch = useAppDispatch();
  const shoppingBagItemList = useAppSelector((state: IRootState)=> state.dineIn.dineInOrder);
  
  const shoppingBagItemNumber = shoppingBagItemList.reduce((acc, curr) => {
    return acc + curr.foodQuantity
  },0)

  return (
    <div className='bottom-dineIn-nav-container'>
      <div onClick={() => {
        dispatch(set_user_action('viewOrder'))
      }}>
        <FontAwesomeIcon icon={faBookOpen} className="nav-icon" />
        <div className='bottom-nav-text'>MY ORDERS</div>
      </div>
      
      <div className='nav-focus-item-wrapper'>
        <div className="nav-focus-item" onClick={()=>{
          dispatch(set_user_action('home'))
        }}>
          <FontAwesomeIcon className="nav-icon" icon={faChampagneGlasses} />
          <div className='bottom-nav-text'>MENU</div>
        </div>
      </div>
      

      <div onClick={()=>{
        dispatch(set_user_action('shoppingBag'))
      }}>
        <FontAwesomeIcon className="nav-icon" icon={faBagShopping} />
        <div className='bottom-nav-text'>SHOPPING BAG</div>
        {shoppingBagItemNumber > 0 ? <div className='shopping-bag-current-item-num'>{shoppingBagItemNumber}</div> : ""}
      </div>
    </div>
  )
}

export default React.memo(DineInNavBar)