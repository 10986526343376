import React, {useState} from "react";
import "./takeawayStyle.scss";
import MyTakeawayOrderItemQR from "./MyTakeawayOrderItemQR";

type MyTakeOrderItemProps = {
  created: any;
  order_status: string;
  total_amount: number;
  id: number;
};

function MyTakeawayOrderItem({created, order_status, total_amount, id}: MyTakeOrderItemProps) {
  const url = `${process.env.REACT_APP_FRONTEND_URL}/admin/takeaway/pickup/${id}`;

  const [showQRCode, setShowQRCode] = useState<boolean>(false);

  const onClickViewQRCodeButton = () => {
    setShowQRCode(!showQRCode);
  }

  return (
    <div className='my-takeaway-order-item-container'>
        <div className='my-takeaway-order-item-container-record-title'>Takeaway</div>
        <div className='my-takeaway-order-item-container-record-title-num'>
            <div className='my-takeaway-order-item-container-order-num-container'>
                <span className='my-takeaway-order-item-container-order-num-number'>Order Number</span>
                <span>{id}</span>
            </div>
        </div>
        
        <div className='my-takeaway-order-item-status-container'>
            <span>Status</span>
            {order_status === "COMPLETED" && <span>{order_status}</span>}
            {order_status === "PREPARING" && <span className='my-takeaway-order-item-status-container-prepare'>{order_status}</span>}
        </div>

        <div className='my-takeaway-order-item-date-container'>
            <span>Order Date</span>
            <span>{new Date(created).toDateString()}</span>
        </div>

      <div className="my-takeaway-order-item-amount-container">
        <span>Total Amount</span>
        <span>${total_amount}</span>
      </div>

      {
        (order_status === "PREPARING") && 
        <div className="takeaway-button-show-qr-container">
          <div className="takeaway-button-show-qr" onClick={() => onClickViewQRCodeButton()}>
            Show QR Code
          </div>
        </div>
      }
      
      

      {showQRCode && <MyTakeawayOrderItemQR id={id} handleCloseButton={onClickViewQRCodeButton}/>}
      
    </div>
  );
}

export default React.memo(MyTakeawayOrderItem);
