import React from 'react'
// import { IRootState } from "../../app/Store";
// import { useAppSelector, useAppDispatch } from "../../app/hook";

import NewOrderButton from './NewOrderButton';
import MakePaymentButton from './MakePaymentButton';

import ViewQRCodeButton from './NewQR/ViewQRCodeButton';


type DineInStatusRowProps = {
    status: string,
    tableNo: number,
    uuid: string
    dispatch: any
}

function DineInStatusRow({tableNo, status, uuid, dispatch}: DineInStatusRowProps) {

  return (
    <>
        <tr>
          <td >{tableNo}</td>
          <td>{status ? "Active" : "Vacant"}</td>
          <td className='admin-dineIn-row'>
            {(status && uuid) ? <ViewQRCodeButton table_num={tableNo} transaction_num={uuid}/> : ""}
            {status ? 
              <MakePaymentButton transaction_num={uuid} table_num={tableNo} dispatch={dispatch}/> : 
              <NewOrderButton tableNum={tableNo} dispatch={dispatch}/>}
          
          </td>
        </tr>
    </>
  )
}

export default React.memo(DineInStatusRow)