import React from 'react'
import { useAppDispatch, useAppSelector } from '../app/hook'; 
import { set_takeaway_user_action } from '../features/takeaway/takeawaySlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChampagneGlasses, faBookOpen, faBagShopping } from '@fortawesome/free-solid-svg-icons';

import './navbarStyle.scss';
import { IRootState } from '../app/Store';

function TakeawayBottomNavbar() {
    const shoppingBagItemList = useAppSelector((state: IRootState)=> state.takeawayStatus.takeawayOrder);
    const dispatch = useAppDispatch();

    const shoppingBagItemNumber = shoppingBagItemList.reduce((acc, curr)=> {
      return acc + curr.foodQuantity
    },0)

    return (
      <div className='bottom-takeaway-nav-container'>
        <div onClick={() => {
          dispatch(set_takeaway_user_action('myOrder'))
          }}>
            <FontAwesomeIcon icon={faBookOpen} className="nav-icon" />
            <div className='bottom-nav-text'>MY ORDERS</div>
        </div>

        <div className='nav-focus-item-wrapper'>
          <div className="nav-focus-item" onClick={()=>{
            dispatch(set_takeaway_user_action('menu'))
          }}>
            <FontAwesomeIcon className="nav-icon" icon={faChampagneGlasses} />
            <div className='bottom-nav-text'>MENU</div>
          </div>
        </div>

        <div onClick={()=>{
          dispatch(set_takeaway_user_action('shoppingBag'))
          }}>
          <FontAwesomeIcon className="nav-icon" icon={faBagShopping} />
          <div className='bottom-nav-text'>SHOPPING BAG</div>
          {shoppingBagItemNumber > 0 ? <div className='shopping-bag-current-item-num'>{shoppingBagItemNumber}</div> : ""}
        </div>
      </div>
    )
}

export default React.memo(TakeawayBottomNavbar)