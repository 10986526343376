import React, {useState, useEffect} from 'react'
import FoodList from '../features/menu/FoodList'
import MenuByCategory from '../features/menu/MenuByCategory';
import { useAppDispatch, useAppSelector } from '../app/hook'
import { init_menu } from '../features/menu/menuSlice';
import { IRootState } from '../app/Store';
import FoodCategoryItem from '../features/menu/FoodCategoryItem';

// import TopNav from '../nav/TopNav';

import './menuPageStyle.scss'
// import { set_user_action } from '../features/dineIn/dineInSlice';

function MenuPage() {

    const [foodCategory, setFoodCategory] = useState<string>('All');
    const categoryList = ['All', 'APPETISERS', 'MAIN', 'DESSERTS', 'DRINKS'];
    const dispatch = useAppDispatch();
    const shoppingBagItemList = useAppSelector((state: IRootState) => state.dineIn.dineInOrder);

    const handleChangeCategory = (category: string) => {
        setFoodCategory(category);
    }

    // const handleClickOrderButton = () => {
    //   dispatch(set_user_action('viewOrder'))
    // }

    // const handleClickHomeButton = () => {
    //   dispatch(set_user_action('home'))
    // }

    // const handleShoppingBarClick = () => {
    //   dispatch(set_user_action('shoppingBag'))
    // }

    useEffect(() => {
        const fetchMenuItems = async () => {
          try {
            const token = localStorage.getItem('customer_token')
            const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/menu/food/new`, {
              headers:{
                "Authorization":`Bearer ${token}`,
              },
            
            });
            const result = await res.json();
            const menuItemList = result;
            let menuItemListFromDb = [...menuItemList];
    
            menuItemListFromDb = menuItemListFromDb.map((item: any)=> {
              return {...item, quantity: 0}
            })
    
            dispatch(init_menu(menuItemListFromDb));
          } catch (error) {
            console.log(error)
          }
        };
        if(shoppingBagItemList.length < 1){
          fetchMenuItems();
        }
      }, []);

    return (
        <div className='menuPageContainer'>
            <div className='menu-section-container'>
                <div className='menu-page-section-title'>menu</div>
                <div className='category-button-container'>
                    {categoryList.map((item)=>
                      <FoodCategoryItem key={item} categoryName={item} onChangeClick={handleChangeCategory}/>
                    )}
                </div>
            </div>
           

            <div className="menuContainer">

              {foodCategory === 'All' && <FoodList/>}
              {foodCategory !== 'All' && <MenuByCategory userChoice={foodCategory}/>}
            </div>
            
        </div>
    )
}

export default React.memo(MenuPage)