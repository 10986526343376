import React from 'react'
import QRCode from "react-qr-code";

import './takeawayStyle.scss';

type MyTakeawayOrderItemQRProps = {
  id: number,
  handleCloseButton: () => void
}

function MyTakeawayOrderItemQR({id, handleCloseButton}: MyTakeawayOrderItemQRProps) {
  const url = `${process.env.REACT_APP_FRONTEND_URL}/admin/takeaway/pickup/${id}`;
  
  return (
    <>
        <div className='takeaway-item-qr-code-master-container'>
          <div className='takeaway-item-qr-code-container'>
            <div className='takeaway-info-qr-container'>
              <span>Takeaway Order #</span>
              <span>{id}</span>
            </div>

            <div className='instruction-div'>Please show QR code to staff</div>

            <div className="takeaway-item-qr-code" style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                  <QRCode
                  size={256}
                  style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                  value={url}
                  viewBox={`0 0 256 256`}/>
            </div> 

            <div className="takeaway-qr-code-close-button" 
              onClick={()=> handleCloseButton()}>Close</div>
          </div> 
          
        </div>
         
    </>
  )
}

export default React.memo(MyTakeawayOrderItemQR)