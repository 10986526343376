import React from 'react'
import AdminDineInStatus from './AdminDineInStatus'
import AdminCheckOrder from './handleOrder/AdminCheckOrder'
import AdminBottomNav from './adminNav/AdminBottomNav';

import { useAppSelector } from "../../app/hook";
import '../admin/adminNav/adminBottomNavStyle.scss'
import AdminTakeawayPage from './AdminTakeaway/AdminTakeawayPage';


function AdminPage() {
  let adminCurrentAction = useAppSelector((state)=> state.admin.adminCurrentAction?.actionType);
  let adminIsAuth = useAppSelector((state)=> state.admin.adminIsAuth);
  // let targetTableNum = (useAppSelector((state)=> state.admin.adminCurrentAction?.actionTableNumber) as number);
  

  return (
    <>
      {(!adminCurrentAction && adminIsAuth) && <AdminDineInStatus/>}
      {(adminCurrentAction === 'home' && adminIsAuth) && <AdminDineInStatus/>}
      {(adminCurrentAction === "payment" && adminIsAuth) && <AdminCheckOrder/>}
      {(adminCurrentAction === "takeaway" && adminIsAuth) && <AdminTakeawayPage/>}
      {(adminCurrentAction === "dineIn" && adminIsAuth) && <AdminDineInStatus/>}
      {/* {adminCurrentAction === "newTable"? <NewTableModal table_num={targetTableNum}/> : ""} */}

      <AdminBottomNav/>
    </>
  )
}

export default React.memo(AdminPage)