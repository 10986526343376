import React from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hook'
import { IRootState } from "../../app/Store";
import ShoppingBagItem from './ShoppingBagItem';
import './shoppingBagStyle.scss'

import { submitDineInOrder } from './dineInAPI';
import { reset_dineInOrder } from './dineInSlice';
import { set_user_action } from './dineInSlice';

import { useMutation, useQueryClient } from '@tanstack/react-query'

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'



function DineInShoppingBag() {
  const MySwal = withReactContent(Swal);
  let transaction_num = (useAppSelector((state: IRootState) =>  state.dineIn.dineInTransactionNumber) as string);
  let currentLocalOrder = useAppSelector((state: IRootState) => state.dineIn.dineInOrder);
  let currentLocalOrderAmount = currentLocalOrder.reduce((acc, curr) => {
    return acc + curr.amount
  },0)

  let currentLocalOrderQty = currentLocalOrder.reduce((acc, curr)=> {
    return acc + curr.foodQuantity
  },0)

  const dispatch = useAppDispatch();
  const queryClient = useQueryClient();

  const handleOnSubmitClick = () => {
    if(currentLocalOrder.length < 1){
      Swal.fire({
        icon: 'info',
        title: 'Please add items to shopping cart first',
        showConfirmButton: false,
        timer: 1500
      })

      return
    }
    onSubmitOrder.mutate({order: currentLocalOrder, transactionNum: transaction_num})


  }

  const onSubmitOrder = useMutation(
    async (data: {order: any, transactionNum: string}) => 
      submitDineInOrder(data.order, data.transactionNum), 
      {
        onSuccess: ()=> {
          queryClient.invalidateQueries(['transactionOrderStatus'])

          Swal.fire({
            icon: 'success',
            title: 'Order submitted',
            showConfirmButton: false,
            timer: 1500
          })
          
          dispatch(set_user_action('home'))
          dispatch(reset_dineInOrder())

        },
        onError: ()=> {
          Swal.fire({
            icon: 'error',
            title: 'Unable to submit order',
            showConfirmButton: false,
            timer: 1500
          })
        }
      }
  )

  const onCancelSubmit = () => {
    dispatch(set_user_action('home'))
  }

  return (
    <>
      <div className='dineInShoppingBagContainer'>
        <div className='user-shopping-bag-section-title'>shopping bag</div>

        <div className='shopping-bag-item-container'>
          {currentLocalOrder?.map((item)=> 
            <ShoppingBagItem key={item.id} id={item.id} itemQty={item.foodQuantity} 
              itemName={item.foodName} itemAmount={item.amount} dispatch={dispatch}/>
          )}
        </div>
        
        <div className='shopping-bag-order-summary'>
          <div>Sub Total</div>

          <div className='total-amount-container'>
            <span>Amount:</span> 
            <span>${currentLocalOrderAmount}</span>
          </div>

          <div className='dish-number-container'>
            <span>Number of Dish:</span> 
            <span>{currentLocalOrderQty}</span>
          </div>
        </div>
        
        
        <div className='submitButtonContainer'>
          <div className='cancelButton' onClick={
            () => onCancelSubmit()
          }>Cancel</div>

          <div className="dineInSubmitOrderButton" onClick={()=>handleOnSubmitClick()}>Submit Order</div>

        </div>
      </div>
    </>
  )
}

export default React.memo(DineInShoppingBag)


  // const fetchMenuItems = async () => {
  //   try {
  //     const backendPath = process.env.REACT_APP_BACKEND_API;
  //     const res = await fetch(`${backendPath}/menu/food/new`, {
  //       headers: {
  //         "Authorization":`Bearer ${localStorage.getItem('token')}` 
  //       }
  //     });


  // const handleSubmitOrderClick = async () => {
  //   // console.log(transaction_num)
  //   const res = await submitDineInOrder(currentLocalOrder, transaction_num);
  //   if(res === true){
  //     Swal.fire({
  //       icon: 'success',
  //       title: 'Order submitted',
  //       showConfirmButton: false,
  //       timer: 1500
  //     })
      
  //     dispatch(reset_dineInOrder())
  //     // await fetchMenuItems();
  //   } else {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Unable to submit order',
  //       showConfirmButton: false,
  //       timer: 1500
  //     })
  //   }
  // }