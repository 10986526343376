import React from 'react'

function HomeIntro() {
  return (
    <div className='animate__animated animate__fadeOut animate__slow home-intro-container'>
      <div className='home-intro-title'>VANÉDIA</div>
    </div>
  )
}

export default React.memo(HomeIntro)