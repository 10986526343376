import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChampagneGlasses, faBookOpen, faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../../app/hook';

import { set_admin_current_action } from '../adminSlice';


type CurrentAction = {
    actionType: string | null,
    actionTableNumber?: number,
    actionTransactionNumber?: string,
    actionSaveAmount?: number
}

function AdminBottomNav() {
    const dispatch = useAppDispatch();

    const handleDineIn = () => {
        const currentAction: CurrentAction = {
            actionType: 'dineIn'
        }
        dispatch(set_admin_current_action(currentAction));
    }

    const handleTakeaway = () => {
        const currentAction: CurrentAction = {
            actionType: 'takeaway'
        }
        dispatch(set_admin_current_action(currentAction));
    }

    const handleAllOrder = () => {
        const currentAction: CurrentAction = {
            actionType: 'allOrder'
        }
        dispatch(set_admin_current_action(currentAction));
    }

    const handleHome = () => {
        const currentAction: CurrentAction = {
            actionType: 'home'
        }
        dispatch(set_admin_current_action(currentAction));
    }
    

    return (
        <div className='admin-bottom-nav-container'>
            <div onClick={handleDineIn}>
                <FontAwesomeIcon icon={faChampagneGlasses} className="nav-icon" />
                <div className='bottom-nav-text'>DINE-IN</div>
            </div>

            {/* <div className='admin-nav-focus-item-wrapper' onClick={handleAllOrder}>

                <div className="admin-nav-focus-item">
                    <FontAwesomeIcon icon={faBookOpen} className="nav-icon" />
                    <div className='bottom-nav-text'>ALL RECORD</div>
                </div>
                
            </div> */}

            <div className='admin-nav-focus-item-wrapper' onClick={handleHome}>

                <div className="admin-nav-focus-item">
                    <FontAwesomeIcon icon={faBookOpen} className="nav-icon" />
                    <div className='bottom-nav-text'>HOME</div>
                </div>
                
            </div>

            {/* <div className='admin-nav-focus-item-wrapper' onClick={handleAllOrder}>

                <div className="admin-nav-focus-item">
                    <FontAwesomeIcon icon={faBookOpen} className="nav-icon" />
                    <div className='bottom-nav-text'>ALL RECORD</div>
                </div>
                
            </div> */}

            <div onClick={handleTakeaway}>
                <FontAwesomeIcon icon={faBagShopping} className="nav-icon" />
                <div className='bottom-nav-text'>TAKEAWAY</div>
            </div>

        </div>
    )
}

export default React.memo(AdminBottomNav)