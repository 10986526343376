import React from 'react'
import { set_admin_current_action } from './adminSlice'
import './adminStyle.scss';

type MakePaymentButtonProps = {
  dispatch: any,
  transaction_num: string,
  table_num: number
}

function MakePaymentButton({dispatch, transaction_num, table_num}: MakePaymentButtonProps) {
  const handleMakePayment = () => {
    const adminAction = {
      actionType: "payment",
      actionTransactionNumber: transaction_num,
      actionTableNumber: table_num
    }
    dispatch(set_admin_current_action(adminAction))
  }
  
  return (
    <div className="make-payment-button" onClick={handleMakePayment}>Payment</div>
  )
}

export default React.memo(MakePaymentButton)