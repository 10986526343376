import React from 'react'
import QRCode from "react-qr-code";
import './qrStyle.scss'


type TableQRCodeProps = {
    table_num: number,
    transaction_num: string,
    onHide: () => void,
}

function TableQRCode({table_num, transaction_num, onHide}: TableQRCodeProps) {
    const url = `${process.env.REACT_APP_FRONTEND_URL}/dineIn/${table_num}/${transaction_num}`

    return (
        <div className='table-qr-code-container'> 
            <div className='qr-code-title'>QR Code for Table {table_num}</div>
            <div className="table-qr-code" style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
                <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={url}
                viewBox={`0 0 256 256`}/>
            </div>  
            
            <div className="qr-code-close-button" onClick={onHide}>Close</div>
        </div>
  )
}

export default React.memo(TableQRCode)