import React from 'react'
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"

import { admin_login_setup } from './adminSlice';
import { useAppDispatch } from "../../app/hook";

import { Form } from "react-bootstrap";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { adminLogin } from './adminAPI';
import './adminStyle.scss';

type AdminLoginInfo = {
    shopName: string
    password: string
}

function AdminLoginForm() {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();

    const { register, handleSubmit, formState: {errors}, watch, reset } = useForm<AdminLoginInfo>({
        defaultValues: {
            shopName: "",
            password: ""
        },
    })

    async function adminSubmitLoginForm(data: AdminLoginInfo){
        let token = await adminLogin(data);
        
        //waiting for token
        if (token){
            Swal.fire({
                icon: 'success',
                title: 'Login success!',
                showConfirmButton: false,
                timer: 1500
            })
            
            // localStorage.setItem("admin_token", token);
            dispatch(admin_login_setup(true));
            reset()
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Incorrect admin phone number/ password',
                showConfirmButton: false,
                timer: 1500
            })
        }
        

    }

    return (
        <div className="admin-login-form-container">
            <div className='admin-login-form-title'>admin login</div>
            <Form  onSubmit={handleSubmit(adminSubmitLoginForm)}>
                <Form.Group>
                    <div className='shop-name-container'>
                        <div>shop name</div>
                        {/* <label>Shop Name */}
                        <input className="shop-name-input" {...register("shopName", {
                                required: "Please fill in Shop Name."
                            })}/>
                    </div>
                    
                    <ErrorMessage errors={errors} name="shopName"/>
                    <ErrorMessage errors={errors} name="shopName" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
                    {/* </label> */}
                    
                    
                </Form.Group>

                <Form.Group>
                    <div className='shop-password-container'>
                        <div>password</div>

                        <input className="shop-password-input" type="password" {...register("password", {required: "Please fill in password", 
                            minLength: {
                                    value: 3,
                                    message: "Password must be at least 3 characters long.",
                                },})}/>
                    </div>
                    
                        <ErrorMessage errors={errors} name="password"/>
                        <ErrorMessage errors={errors} name="password" render={({ messages }) =>
                                messages && Object.entries(messages).map(([type, message]) => (
                                <span style={{ color: "red" }} key={type}>{message}</span>
                            ))}/>
                    {/* </label>                         */}
                        
                </Form.Group>

                <button className="admin-login-button" type="submit">login</button>
            </Form>
        </div>
    )
}

export default React.memo(AdminLoginForm)