import React from 'react'
import './foodStyle.scss';
import { add_food, remove_food } from '../dineIn/dineInSlice';
import { menu_handle_add, menu_handle_minus } from './menuSlice';
import { IRootState } from "../../app/Store";
import { useAppSelector, useAppDispatch } from "../../app/hook";
import './foodStyle.scss'

type FoodItemProps = {
  foodCategory: string,
  id: number,
  foodQty: number,
  foodName: string,
  imageUrl?: string,
  unitPrice: number,
}

type foodOrderItem = {
  transactionNum: string,
  id: number,
  foodName: string,
  foodQuantity: number,
  tableNum: number,
  amount: number,
}

function FoodItem({foodCategory, id, foodQty, foodName, imageUrl, unitPrice}: FoodItemProps) {
  const dispatch = useAppDispatch();
  const transactionNum = (useAppSelector((state: IRootState) => state.dineIn.dineInTransactionNumber) as string);
  const tableNum = (useAppSelector((state: IRootState)=> state.dineIn.tableNumber));

  const addFoodQty = () => {
    // const newQty = foodQty + 1;
    // setFoodQty(newQty);

    let addNewFoodObj: foodOrderItem = {
      transactionNum: transactionNum,
      id: id,
      foodName: foodName,
      foodQuantity: 1,
      tableNum: tableNum as number,
      amount: unitPrice
    }
    
    dispatch(add_food(addNewFoodObj));
    dispatch(menu_handle_add(id));
  }

  const minusFoodQty = () => {
    if(foodQty > 0){
      // let minusQty = foodQty  - 1;
      // setFoodQty(minusQty);

      let minusNewFoodObj: foodOrderItem = {
        transactionNum: transactionNum,
        id: id,
        foodName: foodName,
        foodQuantity: 1,
        tableNum: tableNum as number,
        amount: unitPrice
      }


      dispatch(remove_food(minusNewFoodObj))
      dispatch(menu_handle_minus(id));
    }
  }

  return (
   <div className='food-item-container'>
      
      <img className="foodItemImage" src={imageUrl} alt="" />
      <div className='food-item-name-container'>
        <div className='food-item-name'>{foodName}</div>
      </div>
      

      <div className="food-item-qty-price-container">
        <div className='food-item-price'>${unitPrice}</div>

        <div className='food-qty-container'>
          <div className="food-qty-minus-button" onClick={minusFoodQty}>-</div>
          <div className='food-qty'>{foodQty}</div>
          <div className="food-qty-add-button" onClick={addFoodQty}>+</div>
        </div>
      </div>
      
   </div>
  )
}

export default React.memo(FoodItem)