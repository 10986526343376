import React from 'react'
import '../../pages/menuPageStyle.scss'

type FoodCategoryItemProps = {
    categoryName: string,
    onChangeClick: (str: string) => void
}

function FoodCategoryItem({categoryName, onChangeClick}: FoodCategoryItemProps) {
    const categoryNameEdit = categoryName.toLowerCase().charAt(0).toUpperCase() + categoryName.toLowerCase().slice(1);

    return (
        <div className="category-item" onClick={() => onChangeClick(categoryName)}>
            {categoryNameEdit}
        </div>
  )
}

export default React.memo(FoodCategoryItem)