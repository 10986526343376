import React, {useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import './homeStyle.scss';

function DineInError() {
    const navigate = useNavigate();

    useEffect(()=> {
      const timer = setTimeout(()=> {
        navigate(-1);
      }, 2000)
      return()=> {
        clearInterval(timer)
      }
    }, [navigate])

    return (
      <div className='dineInErrorContainer'>
        <div>Please scan QR code to get start</div>
      </div>
    )
}

export default React.memo(DineInError)