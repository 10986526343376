import React from 'react'
import { takeaway_add_food, takeaway_remove_food } from './takeawaySlice';
import { menu_handle_add, menu_handle_minus } from '../menu/menuSlice';
import { useAppDispatch } from '../../app/hook';
import '../menu/foodStyle.scss'

type FoodItemProps = {
    foodName: string,
    foodCategory: string,
    id: number,
    foodQty: number,
    imageUrl?: string,
    unitPrice: number,
}

type foodOrderItem = {
    id: number,
    foodName: string,
    foodQuantity: number,
    amount: number,
}

function TakeawayFoodItem({foodName, foodCategory, id, foodQty, imageUrl, unitPrice}: FoodItemProps) {
    const dispatch = useAppDispatch();

    const takeawayAddFoodQty = () => {
        let addNewFoodObj: foodOrderItem = {
            id: id,
            foodName: foodName,
            foodQuantity: 1,
            amount: unitPrice
        }
        dispatch(takeaway_add_food(addNewFoodObj))
        dispatch(menu_handle_add(id))
    }

    const takeawayMinusFoodQty = () => {
        if (foodQty > 0){

            let minusNewFoodObj: foodOrderItem = {
                id: id,
                foodName: foodName,
                foodQuantity: 1,
                amount: unitPrice
            }

            dispatch(takeaway_remove_food(minusNewFoodObj))
            dispatch(menu_handle_minus(id))
        }
    }

    return (
        <div className='food-item-container'>
            <img className="foodItemImage" src={imageUrl} alt="" />
            <div className='food-item-name-container'>
                <div className='food-item-name'>{foodName}</div>
            </div>

            <div className="food-item-qty-price-container">
                <div className='food-item-price'>${unitPrice}</div>

                <div className='food-qty-container'>
                    <button className="food-qty-minus-button" onClick={takeawayMinusFoodQty}>-</button>
                    <div className='food-qty'>{foodQty}</div>
                    <button className="food-qty-add-button" onClick={takeawayAddFoodQty}>+</button>
                </div>
            </div>

            {/* <div>
                <button onClick={takeawayMinusFoodQty}>-</button>
                <div>{foodQty}</div>
                <button onClick={takeawayAddFoodQty}>+</button>
            </div> */}
        </div>
    )
}

export default React.memo(TakeawayFoodItem)