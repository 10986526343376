import React, {useState, useEffect} from 'react'
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { init_menu } from '../menu/menuSlice';
import TakeawayFoodList from './TakeawayFoodList';
import TakeawayMenuByCategory from './TakeawayMenuByCategory';
import { IRootState } from '../../app/Store';
import FoodCategoryItem from '../menu/FoodCategoryItem';

import './takeawayStyle.scss';

function TakeawayMenuPage() {
    const [foodCategory, setFoodCategory] = useState<string>('All');
    const categoryList = ['All', 'APPETISERS', 'MAIN', 'DESSERTS', 'DRINKS'];
    const takeawayLocalOrder = useAppSelector((state: IRootState) => state.takeawayStatus.takeawayOrder);
    const dispatch = useAppDispatch();

    const handleChangeCategory = (category: string) => {
      setFoodCategory(category);
    }

    useEffect(() => {
        const fetchMenuItems = async () => {
          try {
            const backendPath = process.env.REACT_APP_BACKEND_API;
            const res = await fetch(`${backendPath}/dining/menu`); //change
            const result = await res.json();
            const menuItemList = result;
            let menuItemListFromDb = [...menuItemList];
    
            menuItemListFromDb = menuItemListFromDb.map((item: any)=> {
              return {...item, quantity: 0}
            })
    
            dispatch(init_menu(menuItemListFromDb));
          } catch (error) {
            console.log(error)
          }
        };
        if(takeawayLocalOrder.length < 1){
            fetchMenuItems();
        }
    }, []);

    return (
        <div className='takeaway-menu-container'>

            <div className='takeaway-menu-header-container'>
              <div className='section-title'>menu</div>
              <div className='category-button-container'>
                      {categoryList.map((item)=>
                        <FoodCategoryItem key={item} categoryName={item} onChangeClick={handleChangeCategory}/>
                      )}
              </div>
            </div>
            {/* <div>
              {categoryList.map((item)=> <button key={item} onClick={
                  () => {
                      setFoodCategory(item)
                  }
              }>{item.toLowerCase().charAt(0).toUpperCase() +item.toLowerCase().slice(1)}</button> )}
            </div> */}
            <div className="takeaway-menu-list-container">
              {foodCategory === 'All' && <TakeawayFoodList/>}
              {foodCategory !== 'All' && <TakeawayMenuByCategory userChoice={foodCategory}/>}
            </div>
            

        </div>
    )
}

export default React.memo(TakeawayMenuPage)