import React, { useState } from 'react'
import '../adminStyle.scss'

import CheckOrderDetails from './CheckOrderDetails';
import CollectPayment from './CollectPayment';

function AdminCheckOrder() {
    const [checkOrderStage, setCheckOrderStage] = useState<string>("checking");

    const checkOrderControl = (stage: string) => {
        setCheckOrderStage(stage)
    }


    return (
        <div className="adminCheckOrderContainer"> 
            {checkOrderStage === "checking"? 
                <CheckOrderDetails flowControl={()=> checkOrderControl("payment")}/> 
                : <CollectPayment flowControl={()=> checkOrderControl("checking")}/>}
        </div>
    )
}

export default React.memo(AdminCheckOrder)

// interface OrderItemFromDb {
//     food_id: string;
//     food_name: string;
//     order_qty: number;
//     amount: number;
// }

// interface OrderItemSummary {
//     food_id: string;
//     food_name: string;
//     total_qty: number;
//     total_amount: number;
// }

// function AdminCheckOrder() {
//     // const [orderCheck, setOrderCheck] = useState([]);
//     // const [showSummary, setShowSummary] = useState<boolean>(false);
//     // const dispatch = useAppDispatch();

//     // const handleBack = () => {
//     //     dispatch(set_admin_current_action(null))
//     // }

//     // const currentTable = useAppSelector((state)=> state.admin.adminCurrentAction?.actionTableNumber);
//     // const current_trans_num = (useAppSelector((state)=> state.admin.adminCurrentAction?.actionTransactionNumber) as string);
//     // const tableOrderSummary = (useAdminCheckTableOrder(current_trans_num)).data;

//     // const [orderTotalPayment, setOrderTotalPayment] = useState<number>(0);
//     // // const [orderTotalItemNum, setOrderTotalItemNum] = useState<number>(0)

//     // useEffect(()=> {
//     //     if (tableOrderSummary){
//     //         let tableOrderSummaryData = [...(tableOrderSummary)];

//     //         tableOrderSummaryData = Object.values(
//     //         tableOrderSummaryData.reduce((acc: { [key: string]: { food_id: string; food_name: string; total_qty: number; total_amount: number } }, item: OrderItemFromDb) => {
//     //         if (acc[item.food_id]) {
//     //             acc[item.food_id].total_qty += item.order_qty;
//     //             acc[item.food_id].total_amount += item.amount;
//     //         } else {
//     //             acc[item.food_id] = { ...item, total_qty: item.order_qty, total_amount: item.amount };
//     //         }
//     //         return acc;
//     //         }, {})
//     //         ) as OrderItemSummary[];
            
//     //         let initAmount = tableOrderSummaryData.reduce((acc, curr: OrderItemSummary)=> {
//     //             return acc + curr.total_amount
//     //         },0)

//     //         // let initItemNum = tableOrderSummaryData.reduce((acc, curr: OrderItemSummary)=> {
//     //         //     return acc + curr.total_qty
//     //         // },0)

//     //         setOrderCheck(tableOrderSummaryData as any)
//     //         setOrderTotalPayment(initAmount)
//     //         // setOrderTotalItemNum(initItemNum);
//     //         setShowSummary(true)
//     //     }

//     // },[tableOrderSummary])

//     // const handleAddOrder = (amountChange: number) => {
//     //     const newAmount = orderTotalPayment + amountChange;
//     //     setOrderTotalPayment(newAmount)
//     // }

//     // const handleMinusOrder = (amountChange: number) => {
//     //     const newAmount = orderTotalPayment - amountChange;
//     //     setOrderTotalPayment(newAmount)
//     // }

//     return (
//         <>  
//             <CheckOrderDetails/>
//             {/* <div onClick={handleBack}>Back</div>
//             <div>Check Order</div>

//             <div>
//                 <span>Dine-in</span> 
//                 <span>Table Number{currentTable}</span>
//             </div>

//             {showSummary && orderCheck?.map((item: OrderItemSummary) => 
//                 <CheckOrderItem key={item.food_id} order_qty={item.total_qty} food_name={item.food_name} amount={item.total_amount} 
//                     addPayment={()=> handleAddOrder(item.total_amount/item.total_qty)}
//                     minusPayment={()=> handleMinusOrder(item.total_amount/item.total_qty)}
//                     />
//             )}

//             <div>Total Amount: ${orderTotalPayment}</div>
//              */}
//         </>
//     )
// }

// export default React.memo(AdminCheckOrder)