import React from 'react'
import { takeaway_add_food, takeaway_remove_food } from './takeawaySlice';
import { menu_handle_add, menu_handle_minus } from '../menu/menuSlice';

import './takeawayStyle.scss';

type TakeawayShoppingBagItemProps = {
    id: number,
    itemQty: number, 
    itemName: string,
    itemAmount: number,
    dispatch: any
}

type foodOrderItem = {
    id: number,
    foodName: string,
    foodQuantity: number,
    amount: number,
}

function TakeawayShoppingBagItem({id, itemQty, itemName, itemAmount, dispatch}: TakeawayShoppingBagItemProps) {
    const handleAddQty = () => {
        let takeawayNewFoodObj: foodOrderItem = {
            id: id,
            foodName: itemName,
            foodQuantity: 1,
            amount: itemAmount
        }

        dispatch(takeaway_add_food(takeawayNewFoodObj))
        dispatch(menu_handle_add(id))
    }

    const handleMinusQty = () => {
        if(itemQty > 0){
            let minusNewFoodObj: foodOrderItem = {
                id: id,
                foodName: itemName,
                foodQuantity: 1,
                amount: (itemAmount / (itemQty as number)),
            }

            dispatch(takeaway_remove_food(minusNewFoodObj))
            dispatch(menu_handle_minus(id))
        }
    }
    
    return (
        <div className="takeaway-shopping-bag-item-container">

            <p className='takeaway-shopping-bag-dish-name'>{itemName}</p>

            <div className='takeaway-shopping-bag-item-qty-amount-container'>
                <div className='takeaway-shopping-bag-item-qty-container'>
                    <div className="shopping-bag-food-qty-minus-button" onClick={handleMinusQty}>-</div>
                    <div className='food-qty'>{itemQty}</div>
                    <div className="food-qty-add-button" onClick={handleAddQty}>+</div>
                </div>

                <div>${itemAmount}</div>
            </div>
            
        </div>
    )
}

export default React.memo(TakeawayShoppingBagItem)