import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message"
import {customer_login_setup} from "./customerAuthSlice";
import { useAppDispatch } from "../../app/hook";

import { customerLoginWithPhone } from "./customerAuthAPI";

import '../../pages/loginPageStyle.scss';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

type CustomerLoginInfo = {
    phone: null|number;
    password: string;
}

type LoginFormProps = {
    changeActionFn: (input:string) => void;
    formSubmitFn: () => void;
}

function LoginForm({changeActionFn, formSubmitFn}: LoginFormProps) {
    const MySwal = withReactContent(Swal);
    const dispatch = useAppDispatch();
    
    const { register, handleSubmit, formState: {errors}, watch, reset } = useForm<CustomerLoginInfo>({
        defaultValues: {
            phone: null,
            password: ""
        },
    })

    const customerPhoneNumber = watch("phone");

    useEffect(()=> {
        let sub = watch((data)=> {
        })
        return () => sub.unsubscribe()
    }, [watch]);

    async function submitLoginForm(data: CustomerLoginInfo){
        const {phone, password} = data;
        const input = {
            phone: (phone as number).toString(),
            password: password
        }

        const token = await customerLoginWithPhone(input)

        if(token){
            Swal.fire({
                icon: 'success',
                title: 'Login success',
                showConfirmButton: false,
                timer: 1500
            })

            formSubmitFn();
            dispatch(customer_login_setup((customerPhoneNumber as number).toString()))
            reset();
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Incorrect phone number/ password',
                showConfirmButton: false,
                timer: 1500
            })
        }
        
    }


    return (
    <div>
        <div className="login-form-title">member login</div>

        <form onSubmit={handleSubmit(submitLoginForm)}>
            <div>
                <Form.Group>
                    <div className="login-input-container">
                        <div>phone number</div>
                        <input  {...register("phone", {
                            required: "Please fill in phone number.",
                            pattern: {
                                value: /\d+/,
                                message: "This input is number only.",
                            },
                            maxLength: {
                                value: 8,
                                message: "Phone number must be 8 characters long.",
                            },
                            minLength: {
                                value: 8,
                                message: "Phone number must be 8 characters long.",
                            },
                        })}/>
                    </div>
                    
                    <ErrorMessage errors={errors} name="phone"/>

                    <ErrorMessage errors={errors} name="phone" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
                </Form.Group>
               
            </div>

            <Form.Group>
                <div className="login-input-container">
                    <div>password</div>
                    <input type="password" {...register("password", {required: "Please fill in password", 
                        minLength: {
                                value: 3,
                                message: "Password must be at least 3 characters long.",
                            },})}/>
                </div>
                
                <ErrorMessage errors={errors} name="password"/>
                <ErrorMessage errors={errors} name="password" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
            </Form.Group>

            <div className="form-button-container">
                <button className="login-form-register-button"  onClick={()=> changeActionFn("register")}>register</button>
                <button className="login-form-login-button" type="submit">login</button>
            </div>
           
        </form>
            {/* <Button onClick={()=> {changeActionFn("register")}}>Register</Button> */}
            
    </div>
  )
}

export default React.memo(LoginForm)