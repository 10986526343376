interface customerLoginInfo {
    phone: string
    password: string
}

export async function customerLoginWithPhone(input: customerLoginInfo){
    try {
        const loginInfo = {
            phone: input.phone,
            password: input.password
        }
    
        const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify(loginInfo)
        });
        const result = await res.json();
        if (res.status === 200) {
            localStorage.setItem("customer_token", result.token)
            return result.token
        } else {
            return false
        }
    } catch (err){
        return false
    }
}

interface customerRegisterInfo {
    customer_name: string,
    phone: string,
    email: string,
    password: string,
}

export async function customerRegister(input: customerRegisterInfo){
    try {
        const registerInput = {
            customer_name: input.customer_name,
            phone: input.phone,
            email: input.email,
            password: input.password,
        }
    
        const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/signup`, {
            method: "POST", 
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(registerInput)
        })
    
        if (res.status === 200){
            const result = await res.json();
            // localStorage.setItem("customer_token", result.token)
            return result.token
        } else {
            return false
        }
    } catch (err){
        return false
    }
    
}

// export async function customerLoginWithEmail(email: number, password: string){
//     const loginInfo = {
//         email: email,
//         password: password
//     }

//     const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/user/login`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json"
//         }, 
//         body: JSON.stringify(loginInfo)
//     });

//     const result = await res.json();
//     return result
// }
