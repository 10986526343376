import React, { useEffect, useState }  from 'react'
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { customerSubmitTakeawayOrder } from './takeawayAPI';

import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { StripeCardElement } from "@stripe/stripe-js";

import Swal from 'sweetalert2/dist/sweetalert2.js';
import withReactContent from 'sweetalert2-react-content'
import './takeawayStyle.scss';
import { IRootState } from '../../app/Store';
import { reset_takeawayOrder, set_takeaway_user_action } from './takeawaySlice';

type TakeawayOrderContactInfo = {
    customer_name: string,
    contact_number: string
}

type TakeawayOrderRecord = {
    transactionsUuid: string,
    foodId: number,
    orderQty: number,
    amount: number
}

const style = {
    base: {
        fontFamily: "Source Code Pro, monospace",
        fontLineHeight: '2',
        borderRadius: '10px',
        colorBackground: '#F6F8FA',
        colorPrimaryText: "#8d8d8d",
        fontSize: '18px',
        fontSmoothing: 'antialiased',
        ':-webkit-autofill': {
            color: '#fce883',
        },
        '::placeholder': {
            color: "#8d8d8d",
        },
    },
    invalid: {
      iconColor: '#B55757',
      color: '#B55757',
    },
}


function TakeawayOrderContactForm() {
    let takeawayOrderRecord = useAppSelector((state: IRootState) => state.takeawayStatus.takeawayOrder);
    const stripe = useStripe();
    const elements = useElements();
    const MySwal = withReactContent(Swal);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const takeawayPaymentAmount = takeawayOrderRecord.reduce((acc, curr) => {
        return acc + curr.amount
    }, 0)

    const { register, handleSubmit, formState: {errors}, watch } = useForm<TakeawayOrderContactInfo>({
        defaultValues: {
            customer_name: "",
            contact_number: ""
        },
    })

    useEffect(()=> {
        let sub = watch((data)=> {
        })
        return () => sub.unsubscribe()
    }, [watch]);

    const handleCancelButtonClick = () => {
        Swal.fire({
            title: 'Do you want to cancel order submission?',
            showDenyButton: true,
            confirmButtonText: 'Confirm',
        }).then((result) => {
            if (result.isConfirmed) {
              Swal.fire('Cancelled', '', 'info');
              dispatch(set_takeaway_user_action('menu'))
            } 
          })
    }

    const handleSubmitOrderForm = async (data: TakeawayOrderContactInfo) => {
        try {
            let {customer_name, contact_number} = data;
            contact_number = contact_number as string
            const newTransactionNum = uuidv4() as string;
    
            if (!stripe || !elements) {
                return;
            }
    
            const payload = await stripe.createPaymentMethod({
                type:"card",
                card: elements.getElement(CardElement) as StripeCardElement
            });
    
            const customerToken = localStorage.getItem('customer_token');
            setIsLoading(true);

            const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/takeaway/paymentConfirm`, {
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`Bearer ${customerToken}` 
                },
                body: JSON.stringify({
                    payment_method_id: payload.paymentMethod?.id,
                    amount:1009,
                    currency:"hkd"
                }),
            })
            
            const result = await res.json();
            if(result.status === "succeeded"){
                
                const modifyTakeawayOrderRecord = takeawayOrderRecord.map((item)=> {
                    return {
                        transactionsUuid: newTransactionNum,
                        foodId: item.id,
                        orderQty: item.foodQuantity,
                        amount: item.amount
                    }
                });
    
                const customerOrder = {
                    "mini_orders_takeaway": modifyTakeawayOrderRecord,
                    "orders_takeaway": {
                        "payment_method": "CREDITCARD",
                        "payment_status": "COMPLETED",
                        "total_amount": takeawayPaymentAmount,
                        "uuid": newTransactionNum,
                        "contact_name": customer_name,
                        "contact_phone":contact_number
                    }
                }
    
                const submitResult = await customerSubmitTakeawayOrder(customerOrder, newTransactionNum);
    
                if (submitResult){
                    setIsLoading(false);

                    Swal.fire({
                        icon: 'success',
                        title: 'Order submitted',
                        showConfirmButton: false,
                        timer: 1500
                    })
    
                    dispatch(set_takeaway_user_action("menu"));
                    dispatch(reset_takeawayOrder());
    
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Unable to submit order',
                        showConfirmButton: false,
                        timer: 1500
                    })
                }            
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to submit order',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        } catch (err){
            Swal.fire({
                icon: 'error',
                title: 'Unable to submit order',
                showConfirmButton: false,
                timer: 1500
            })
        }
    }

    if(isLoading){
        Swal.fire({
            title: 'Submitting order...',
            showConfirmButton: false,
        })
        Swal.showLoading();
    }

    return (
        <div className='takeaway-contact-info-container'>
            
            <div className='takeaway-contact-info-header'>Contact Info</div>

            <form onSubmit={handleSubmit(handleSubmitOrderForm)}>
                <div className='takeaway-contact-info-input-container'>
                    <label>Name</label>
                    <input {...register("customer_name", {
                        required: "Please fill in customer name.", 
                        minLength: {
                            value: 3,
                            message: "Name must be at least 3 characters.",
                        }
                    })}/>
                </div>
            <ErrorMessage errors={errors} name="customer_name"/>
            <ErrorMessage errors={errors} name="customer_name" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
            
            <div className='takeaway-contact-info-input-container'>
                    <label>Phone Number</label>
                    <input {...register("contact_number", {
                        required: "Please fill in phone number.",
                        pattern: {
                            value: /\d+/,
                            message: "This input is number only.",
                        },
                        maxLength: {
                            value: 8,
                            message: "Phone number must be 8 characters long.",
                        },
                        minLength: {
                            value: 8,
                            message: "Phone number must be 8 characters long.",
                        },
                    })}/>
            </div>
            <ErrorMessage errors={errors} name="contact_number"/>
            <ErrorMessage errors={errors} name="contact_number" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
            
            

            <div className="takeaway-payment-info-input-container">
                <div className='takeaway-payment-info-header'>Payment Info</div>
                <CardElement options = {{hidePostalCode: true, style: style}}/>
            </div>

            <div className='takeaway-payment-amount-container'>
                <span>Total Payment: </span>
                <span>${takeawayPaymentAmount}</span>
            </div>
            
            <div className='takeaway-form-button-container'>
                <button onClick={handleCancelButtonClick} className='takeaway-form-cancel-button'>Cancel</button>
                <button className='takeaway-form-submit-button' type="submit">Submit Order</button>
            </div>
            
            </form>
        </div>
        
    )
}

export default React.memo(TakeawayOrderContactForm)