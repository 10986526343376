import React, { useState } from 'react'
// import Login from '../../pages/Login';
// import TakeawayLoginModal from './TakeawayLoginModal';
import { useAppDispatch } from '../../app/hook';
import { set_takeaway_user_action } from './takeawaySlice';
import './takeawayStyle.scss';

function TakeawayLoginModalButton() {
    // const [show, setShow] = useState(false);
    // // const [userAuthAction, setUserAuthAction] = useState('login');
    // const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    const dispatch = useAppDispatch();

    return (
        <>
            {/* <div className='takeaway-login-modal-button' 
                onClick={handleShow}>
                Login/ Register to Submit Order
            </div> */}
            <div className='takeaway-next-button' 
                onClick={()=> {
                    dispatch(set_takeaway_user_action('userAuth'))
                }}>
                Login/ Register to Submit Order
            </div>

            {/* {show && <TakeawayLoginModal onHide={handleClose}/>} */}
        </>
    )
}

export default React.memo(TakeawayLoginModalButton)