import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type orderItemSummary = {
    food_id: string;
    food_name: string;
    total_qty: number;
    total_amount: number;
}

type tableStatusType = {
    table_num: number,
    table_status: string,
    current_trans_num: string,
}

type tableStatusState = {
    allTableStatus: tableStatusType[],
    targetTableSummary: null | orderItemSummary[]
}

const initialState: tableStatusState = {
    allTableStatus: [],
    targetTableSummary: null
}

export const tableStatusSlice = createSlice({
    name: "tableStatus",
    initialState, 
    reducers: {
        table_new_transNum: (state, action: PayloadAction<number>) => {
            const targetTableNum = action.payload as number;
            let newStatus = [...(state.allTableStatus)];
            newStatus = newStatus.map((item)=> {
                if(item.table_num === targetTableNum){
                    return {...item, current_trans_num: "123"}
                }
                return item
            })

            return {
                ...state, allTableStatus: newStatus
            }
        },
        // set_transaction_summary: (state, action: PayloadAction<orderItemSummary[]>) => {
        //     let targetTableSummary = action.payload as orderItemSummary[];
        //     let modify = [...(state.targetTableSummary)]
        // }
    }
})

export const {table_new_transNum} = tableStatusSlice.actions;
export default tableStatusSlice.reducer