import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type currentAction = {
    actionType: string | null,
    actionTableNumber?: number,
    actionTransactionNumber?: string,
    actionSaveAmount?: number
}

type adminAuthState = {
    adminIsAuth: boolean,
    adminCurrentAction: currentAction | null
}

const initialState: adminAuthState = {
    adminIsAuth: localStorage.getItem('adminToken') !== null,
    adminCurrentAction: null
}

export const adminSlice = createSlice({
    name: "adminAuth",
    initialState,
    reducers: {
        admin_login_setup: (state, action: PayloadAction<boolean>) => {
            state.adminIsAuth = true
        },
        admin_logout_setup: (state) => {
            localStorage.removeItem("adminToken");
            state.adminIsAuth = false
            state.adminCurrentAction = null
        },
        set_admin_current_action: (state, action: PayloadAction<currentAction|null>) => {
            state.adminCurrentAction = action.payload
        },
        admin_save_current_amount : (state, action: PayloadAction<number>)=> {
            if (state.adminCurrentAction !== null){
                state.adminCurrentAction.actionSaveAmount = action.payload as number
            }
        }
    }
})

export const {admin_login_setup, admin_logout_setup, set_admin_current_action, admin_save_current_amount} = adminSlice.actions;
export default adminSlice.reducer;