import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type customerAuthState = {
    customerIsAuth : boolean;
    customer_phoneNumber: null|string;
    customer_userId: null|String;
    customer_mode: null | string;
}

const initialState: customerAuthState = {
    customerIsAuth: localStorage.getItem('customer_token') !== null,
    customer_phoneNumber: null,
    customer_userId: null,
    customer_mode: null
};

type customerInfo = {
    // customer_username: string,
    // customer_userId: string
    customer_phoneNumber: string
}

export const customerAuthSlice = createSlice({
    name: "customerAuth",
    initialState,
    reducers:{
        customer_login_setup: (state, action: PayloadAction<string|null>) => {
            state.customerIsAuth = true;
            state.customer_phoneNumber = action.payload;
            // state.customer_userId = action.payload.customer_userId;
        },
        customer_logout: (state) => {
            if (state.customerIsAuth){
                localStorage.removeItem('customer_token')
                state.customerIsAuth = false;
                state.customer_phoneNumber = null;
                state.customer_userId = null
            }
        },
        customer_register_setup: (state, action: PayloadAction<string|null>) => {
            state.customerIsAuth = true;
            state.customer_phoneNumber = action.payload;
            // state.customer_userId = action.payload.customer_userId;
        },
        set_customer_mode : (state, action: PayloadAction<string|null>) => {
            state.customer_mode = action.payload;
        }
    },
});

export const { customer_login_setup, customer_logout, customer_register_setup, set_customer_mode } = customerAuthSlice.actions;
export default customerAuthSlice.reducer;
