import React from 'react';
import { useAppDispatch, useAppSelector } from '../app/hook';
import { IRootState } from '../app/Store';
import { customer_logout } from '../features/customerAuth/customerAuthSlice';
import { admin_logout_setup } from '../features/admin/adminSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRightFromBracket, faXmark  } from '@fortawesome/free-solid-svg-icons';
import './offcanvasStyle.scss'
import { clear_user_dineIn_data } from '../features/dineIn/dineInSlice';
import { reset_takeaway_state } from '../features/takeaway/takeawaySlice';

type CustomerOffcanvasProps = {
  closeCanvas: () => void;
}

function CommonOffcanvas({closeCanvas}: CustomerOffcanvasProps) {
    const isCustomer = useAppSelector((state: IRootState) => state.customerAuth.customerIsAuth);
    const isAdmin = useAppSelector((state: IRootState) => state.admin.adminIsAuth);
    const mode = useAppSelector((state: IRootState) => state.customerAuth.customer_mode);

    const dispatch = useAppDispatch();

    const onClickClose = () => {
      closeCanvas()
    }

    const onLogout = () => {
      if(isCustomer){
        dispatch(customer_logout());
        dispatch(reset_takeaway_state());
        dispatch(clear_user_dineIn_data()); 
      }

      if(isAdmin){
        dispatch(admin_logout_setup());
        dispatch(reset_takeaway_state());
        dispatch(clear_user_dineIn_data()); 
      }

      closeCanvas()
    }



    return (
      <>
        <div className='animate__animated animate__slideInRight offcanvas-container'>
                <div className="box-container">
                    <div onClick={() => onClickClose()} className='close-button'><FontAwesomeIcon icon={faXmark} /></div>
                        {(isCustomer || isAdmin) && 
                        <div className='logout-container'>
                            <div onClick={()=>  onLogout()} className='logoutButton'>
                                <FontAwesomeIcon icon={faRightFromBracket} />
                                <span>Logout</span>
                            </div>
                        </div>}

                        {(!isCustomer && !isAdmin) && <div>Please login first</div>}
                </div>
            </div>
      </>
    )
}

export default React.memo(CommonOffcanvas)