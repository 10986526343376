import React from 'react'
import AdminLogin from './AdminLogin'
import AdminPage from './AdminPage';
// import TableQRCode from './NewQR/TableQRCode';
import TopNav from '../../nav/TopNav';

import './adminStyle.scss'

import { useAppSelector } from '../../app/hook';
import { IRootState } from '../../app/Store';

function AdminHome() {
    let adminAuthState = useAppSelector((state: IRootState) => state.admin.adminIsAuth);
    
  return (
    <>
      <TopNav/>
      
      <div className='adminPageContainer'>
              {adminAuthState ? <AdminPage/> : <AdminLogin/>}
      </div>
    </>
    
    
  )
}

export default React.memo(AdminHome)