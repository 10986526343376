import React, {useState} from 'react'
import Table from 'react-bootstrap/Table';

import DineInStatusRow from './DineInStatusRow';
import { useAppDispatch } from "../../app/hook";

import { useAdminTableStatus } from './adminAPI';
import './adminStyle.scss';


type tableStatusData = {
    status: string,
    tableNo: number,
    uuid: string
}

type DineInStatusRow = {
    status: string,
    tableNo: number,
    uuid: string
    dispatch: any
}

function AdminDineInStatus() {
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useAppDispatch();
    const tableStatus: tableStatusData[] = useAdminTableStatus();

    return (
        <div className='admin-table-container'>
            <div className='admin-dinein-page-header-container'>dine-in status</div>
            <div className='admin-dinein-order-container'>
                <table className='admin-table'>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
                </thead>

                <tbody>
                    {tableStatus.map(item => 
                        <DineInStatusRow key={item.tableNo} tableNo={item.tableNo} status={item.status} 
                            uuid={item.uuid} dispatch={dispatch}/>
                    )}
                </tbody>
            </table>
            </div>
            

        </div>
    )
}

export default React.memo(AdminDineInStatus)