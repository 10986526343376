import React from 'react'
import AdminLoginForm from './AdminLoginForm'

function AdminLogin() {
  return (
    // <div className='adminLoginPageContainer'>
    <>
      <AdminLoginForm/>
    </>
       
  )
}

export default React.memo(AdminLogin)