import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import './homeStyle.scss';

function NotFound() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2000);

    return () => {
      clearInterval(timer);
    };
  }, [navigate]);
  return (
    <div className='not-found-page-container'>
        <h3>404 Not Found</h3>
        <p>
        You seems to be going to a non-existent page. You are going to be
      redirected back to home page...
        </p>
    </div>
    
    
  )
}

export default NotFound;
