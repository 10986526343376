import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

// interface menuItemsFromDb {
//     food_type: string,
//     food_id: string,
//     food_name: string,
//     image_url?: string,
//     unit_price: number,
//     quantity: number,
// }

interface menuItemsFromDb {
    id: number,
    foodName: string,
    imageUrl: string,
    unitPrice: number,
    stockStatus: boolean,
    foodTypeName: string, 
    quantity: number
}


type MenuItemState = {
    allItems: menuItemsFromDb[]
}

const initialState: MenuItemState = {
    allItems: []
};

export const menuSlice = createSlice({
    name:"menuItem",
    initialState,
    reducers: {
        init_menu: (state: MenuItemState, action: PayloadAction<menuItemsFromDb[]>) => {
           return{
                ...state, allItems: action.payload
           } 
        },
        menu_handle_add: (state: MenuItemState, action: PayloadAction<number>)=>{
            let allMenuList = [...state.allItems];
            allMenuList = allMenuList.map((item) => {
                if(item.id === action.payload){
                    return {...item, quantity: item.quantity + 1}
                }
                return item
            });

            return {...state, allItems: allMenuList}
        },
        menu_handle_minus: (state: MenuItemState, action:PayloadAction<number>) => {
            let allMenuList = [...state.allItems];
            allMenuList = allMenuList.map((item) => {
                if(item.id === action.payload){
                    if(item.quantity > 0){
                        return {...item, quantity: item.quantity - 1}
                    }
                }
                return item
            });
            return {...state, allItems: allMenuList}
        },
        reset_menu: (state: MenuItemState, action: PayloadAction<menuItemsFromDb[]>) => {
            state.allItems = action.payload;
        }
    }
})

export const { init_menu, menu_handle_minus, menu_handle_add, reset_menu } = menuSlice.actions;
export default menuSlice.reducer;