import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { createDineInNewTransaction } from './adminAPI';
import { set_admin_current_action } from './adminSlice'
// import { createDineInTransactionNum, createDineInNewTransaction } from './adminAPI';
// import { v4 as uuidv4 } from "uuid";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import './adminStyle.scss';

type NewOrderModalProps = {
  tableNum: number,
  handleNewOrder: (num: number) => void,
  handleClose: () => void,
  dispatch: any
}

function NewOrderModal({tableNum,handleNewOrder, handleClose, dispatch }: NewOrderModalProps) {
  const MySwal = withReactContent(Swal);
  const queryClient = useQueryClient();
  
  // const onAddTable = useMutation(
  //   async (data: {uuid: string, table_id: number}) => 
  //   createDineInTransactionNum(data.uuid, data.table_id), 
  // {
  //   onSuccess: () => {
  //     Swal.fire({
  //           icon: 'success',
  //           title: 'Created new order',
  //           showConfirmButton: false,
  //           timer: 1500
  //       })
  //     dispatch(set_admin_current_action(null))
  //     queryClient.invalidateQueries(['tableStatus'])
      
  //   },
  //   onError: ()=> {
  //     Swal.fire({
  //           icon: 'error',
  //           title: 'Could not create new order',
  //           showConfirmButton: false,
  //           timer: 1500
  //       })
  //   }
  //   }
  // )

  const onAddTable = useMutation(
    async (data: {table_id: number}) => 
    createDineInNewTransaction(data.table_id), 
  {
    onSuccess: () => {
      Swal.fire({
            icon: 'success',
            title: 'Created new order',
            showConfirmButton: false,
            timer: 1500
        })
      dispatch(set_admin_current_action(null))
      queryClient.invalidateQueries(['tableStatus'])
      
    },
    onError: ()=> {
      Swal.fire({
            icon: 'error',
            title: 'Could not create new order',
            showConfirmButton: false,
            timer: 1500
        })
    }
    }
  )

  return (
        <div className='new-order-modal-container-shadow'>
          <div className='new-order-modal-container'>
              <div>
                <div style={{fontSize: "20px"}}>Table Number {tableNum}</div>
              </div>

            <div className='new-order-button-container'>
              <div className="new-order-trigger-button" onClick={()=> {
                    handleNewOrder(tableNum)
                    // const newTransactionNum = uuidv4();
                    onAddTable.mutate({table_id: tableNum})
              }}>Create Order
              </div>

              <div className="new-order-cancel-button" onClick={handleClose}>Close</div>
                        
            </div>
          </div>
        </div>
        

  )
}

export default React.memo(NewOrderModal)