import React from 'react';
import { Routes, Route, Navigate } from "react-router-dom";
import './App.scss';
// import { useAppSelector } from './app/hook';
// import { IRootState } from './app/Store';

import NotFound from './pages/NotFound';
import Home from './pages/Home';
import DineIn from './features/dineIn/DineIn';
import AdminHome from './features/admin/AdminHome';
import TakeawayHome from './pages/TakeawayHome';
import DineInCheck from './pages/DineInCheck';
// import DineInHome from './pages/DineInHome';
// import DineInShoppingBag from './features/dineIn/DineInShoppingBag';
import ModeSwitch from './nav/ModeSwitch';

function App() {
  // let customerAuthState = useAppSelector((state: IRootState)=> state.customerAuth.customerIsAuth);

  return (
    <div>
      {/* <header>
        <TopNavBar/>
      </header> */}

      <Routes>
        <Route path="/" element={<Navigate to="/home"/>}></Route>
        <Route path="/home" element={ <Home/> }></Route>
        <Route path="/takeaway" element={<TakeawayHome/>}></Route>
        <Route path="/admin" element={<AdminHome/>}></Route>
        <Route path="/dineIn" element={<DineInCheck/>}></Route>
        <Route path="/dineIn/:tableNum/:transactionNum" element={<DineIn/>}></Route>
        <Route path="*" element={<NotFound/>}></Route>
      </Routes>


      <ModeSwitch/>
    </div>
  );
}

export default React.memo(App);