import React from 'react'
import './orderStyle.scss'

type OrderSummaryItemProps = {
  id: number;
  food_name: string;
  total_qty: number;
  total_amount: number;
}

function ViewOrderItem({id, food_name, total_qty, total_amount}:OrderSummaryItemProps) {
  return (
    <div className='viewOrderItemContainer'>
      <span>{total_qty}x</span>
      <span>{food_name}</span>
      <span>${total_amount}</span>
    </div>
  )
}

export default React.memo(ViewOrderItem)