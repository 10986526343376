import React, { useState } from "react";
import { useAdminAllTakeawayOrders } from "../adminAPI";
import AdminTakeawayItem from "./AdminTakeawayItem";
import Table from "react-bootstrap/Table";
import "../adminStyle.scss";

type AllTakeawayOrders = {
  id: number;
  orderStatus: string;
  contactPhone: string;
  uuid: string
};

function AdminTakeawayPage() {
  let takeawayStatus = useAdminAllTakeawayOrders() as AllTakeawayOrders[];
  const [sortingMode, setSortingMode] = useState<string>("number");

  // let mockTakeawayStatus = [
  //     {id: 1, order_status: "PREPARING", contact_phone: "98765432"},
  //     {id: 2, order_status: "PREPARING", contact_phone: "98765431"},
  //     {id: 3, order_status: "PICKUP", contact_phone: "98765430"},
  //     {id: 4, order_status: "PREPARING", contact_phone: "98765432"},
  //     {id: 5, order_status: "PREPARING", contact_phone: "98765431"},
  //     {id: 6, order_status: "PICKUP", contact_phone: "98765430"},
  //     {id: 7, order_status: "COMPLETED", contact_phone: "98765439"},
  //     {id: 8, order_status: "PREPARING", contact_phone: "98765432"},
  //     {id: 9, order_status: "PREPARING", contact_phone: "98765431"},
  //     {id: 10, order_status: "PICKUP", contact_phone: "98765430"},
  //     {id: 11, order_status: "PREPARING", contact_phone: "98765432"},
  //     {id: 12, order_status: "PREPARING", contact_phone: "98765431"},
  //     {id: 13, order_status: "PICKUP", contact_phone: "98765430"},
  //     {id: 14, order_status: "COMPLETED", contact_phone: "98765439"},
  // ] as AllTakeawayOrders[];

  if (sortingMode === "number") {
    takeawayStatus.sort((a, b) => a.id - b.id);
  } else if (sortingMode === "status") {
    takeawayStatus.sort((a, b) => {
      const order = ["PREPARING", "PICKUP", "COMPLETED"];
      return order.indexOf(a.orderStatus) - order.indexOf(b.orderStatus);
    });
  }

  return (
    <div className="admin-takeaway-page-container">
      <div className="admin-takeaway-page-header-container">takeaway</div>
      <div className="sorting-mode-container">
        <div className="sorting-mode-container-sortBy">Sort By:</div>
        <div className="sorting-mode-container-options">
          <div
            onClick={() => {
              setSortingMode("number");
            }}
          >
            number
          </div>
          <div
            onClick={() => {
              setSortingMode("status");
            }}
          >
            status
          </div>
        </div>
      </div>
      <div className="admin-takeaway-page-orders-container">
        <table className="admin-takeaway-table">
          <thead>
            <tr>
              <th>#</th>
              <th>Phone</th>
              <th>Status</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {takeawayStatus.map((item) => (
              <AdminTakeawayItem
                key={item.id}
                id={item.id}
                orderStatus={item.orderStatus}
                contactPhone={item.contactPhone}
                uuid={item.uuid}
              />
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default React.memo(AdminTakeawayPage);
