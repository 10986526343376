import React from 'react'
import { IRootState } from '../app/Store'
import { useAppSelector } from '../app/hook'

import DineInHome from './DineInHome'
import TakeawayHome from './TakeawayHome'
import HomeDirect from './HomeDirect'

// import { set_user_action } from '../features/dineIn/dineInSlice'
// // import { init_menu } from '../features/menu/menuSlice'
// import ViewOrder from '../features/order/ViewOrder'
// import BottomNavBar from '../nav/DineInNavBar'
// import MenuPage from './MenuPage'

function Home() {
    const customerMode = useAppSelector((state: IRootState) => state.customerAuth.customer_mode);
  // let customerAuthState = useAppSelector((state: IRootState)=> state.customerAuth.customerIsAuth);
  // let dineInOrderRecord = useAppSelector((state: IRootState)=> state.dineIn.dineInOrder);
  // let userAction = useAppSelector((state: IRootState) => state.dineIn.userAction);
  // const dispatch = useAppDispatch();

  // const handleClickOrderButton = () => {
  //   dispatch(set_user_action('viewOrder'))
  // }

  // const handleClickHomeButton = () => {
  //   dispatch(set_user_action('home'))
  // }

  // const handleStatusBarClick = () => {
  //   dispatch(set_user_action('shoppingBag'))
  // }

  return (
    <>
      {customerMode === null && <HomeDirect/>}
      {customerMode === 'dineIn' && <DineInHome/>}
      {customerMode === 'takeaway' && <TakeawayHome/>}
    </>
    // <div className='pageContainer'>  

    //   {(userAction === 'home' || userAction === null) && <MenuPage/>}
    //   {userAction === 'viewOrder' && <ViewOrder/>}
    //   {userAction === 'shoppingBag' && <DineInShoppingBag/>}

    //   {(dineInOrderRecord.length > 0 && userAction !== 'shoppingBag') ? <StatusBar handleClick={handleStatusBarClick}/> : ""}

    //   <footer>
    //     <BottomNavBar clickHome={handleClickHomeButton} clickOrderSummary={handleClickOrderButton}/>
    //   </footer>
    // </div>
    
  )
}

export default React.memo(Home)