import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hook';
import { set_admin_current_action, admin_save_current_amount } from '../adminSlice';

import { useAdminCheckTableOrder, adminConfirmOrderAmount } from '../adminAPI';
import CheckOrderItem from './CheckOrderItem';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'

import "../adminStyle.scss"

interface OrderItemFromDb {
    foodId: string;
    foodName: string;
    orderQty: number;
    amount: number;
}

interface OrderItemSummary {
    foodId: string;
    foodName: string;
    total_qty: number;
    total_amount: number;
}

type CheckOrderDetailsProps = {
    flowControl: (stage: string) => void
}

function CheckOrderDetails({flowControl}: CheckOrderDetailsProps) {
    
    const dispatch = useAppDispatch();
    const MySwal = withReactContent(Swal);

    const handleBack = () => {
        dispatch(set_admin_current_action(null))
    }

    const currentTable = useAppSelector((state)=> state.admin.adminCurrentAction?.actionTableNumber);
    const current_trans_num = (useAppSelector((state)=> state.admin.adminCurrentAction?.actionTransactionNumber) as string);
    let tableOrderSummary = (useAdminCheckTableOrder(current_trans_num));

    console.log(tableOrderSummary)
    tableOrderSummary = tableOrderSummary.map((item: any) => {
        return {
            foodId: item.foodId,
            foodName: item.foodName,
            orderQty: item.orderQty,
            amount: item.amount
        }
    })

    let tableOrderSummaryData = [...tableOrderSummary];

    tableOrderSummaryData = Object.values(
    tableOrderSummaryData.reduce((acc: { [key: string]: { foodId: string; foodName: string; total_qty: number; total_amount: number } }, item: OrderItemFromDb) => {
            if (acc[item.foodId]) {
                acc[item.foodId].total_qty += item.orderQty;
                acc[item.foodId].total_amount += item.amount;
            } else {
                acc[item.foodId] = { ...item, total_qty: item.orderQty, total_amount: item.amount };
            }
            return acc;
            }, {})
            ) as OrderItemSummary[];
            
    let initAmount = tableOrderSummaryData.reduce((acc, curr: OrderItemSummary)=> {
        return acc + curr.total_amount
    },0);
    
    const [orderTotalPayment, setOrderTotalPayment] = useState<number>(0);
   

    useEffect(()=> {
        setOrderTotalPayment(initAmount);
    },[initAmount])
    
    

    const handleAddOrder = (amountChange: number) => {
        const newAmount = orderTotalPayment + amountChange;
        setOrderTotalPayment(newAmount)
    }

    const handleMinusOrder = (amountChange: number) => {
        const newAmount = orderTotalPayment - amountChange;
        setOrderTotalPayment(newAmount)
    }

    const handleProceedPayment = async () => {

        dispatch(admin_save_current_amount(orderTotalPayment));

        const input = {
            customerId: 1,
            shopId: 1,
            totalAmount: orderTotalPayment,
            transactionsUuid: current_trans_num,
        }

        const result = await adminConfirmOrderAmount(input);

        if(result){
            flowControl("payment");
        } else {
            Swal.fire({
                icon: 'error',
                title: 'Failed to confirm order',
                showConfirmButton: false,
                timer: 1500
            })
        }
        // flowControl("payment");
    }

    return (
        <div className='admin-check-order-details-container'>  
            
            <div className='admin-section-title'>dine-in review order</div>
            <div className='review-order-and-table-num-container'>
                <div className='check-order-table-num-container'>
                    <span>Table Number </span><span>{currentTable}</span>
                </div>
            </div>
            

            <div className='check-order-details-container'>
                {tableOrderSummaryData?.map((item: OrderItemSummary) => 
                    <CheckOrderItem key={item.foodId} order_qty={item.total_qty} food_name={item.foodName} amount={item.total_amount} 
                        addPayment={()=> handleAddOrder(item.total_amount/item.total_qty)}
                        minusPayment={()=> handleMinusOrder(item.total_amount/item.total_qty)}
                        />
                )}

                {tableOrderSummaryData.length < 1 ? <div>NO ITEM</div> : ""}
            </div>
            
            <div className='check-order-footer-container'>
                <div className='check-order-amount-container'><span>Total Amount: </span><span>${orderTotalPayment}</span></div>

                <div className='button-container'>
                    {tableOrderSummaryData.length < 1 ? <div style={{width: "100%"}}className="back-button" onClick={handleBack}>Back</div> : <div className="back-button" onClick={handleBack}>Back</div>}
                    {tableOrderSummaryData.length > 1 ? <div className="next-button" onClick={handleProceedPayment}>Proceed Payment</div> : ""} 
                </div>
            </div>
            
            
        </div>
    )
}

export default React.memo(CheckOrderDetails)