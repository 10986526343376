import React, {useState} from 'react'
import { useAppDispatch } from '../app/hook'
import { IRootState } from '../app/Store'
import { useNavigate } from 'react-router-dom'
import { set_customer_mode } from '../features/customerAuth/customerAuthSlice'

import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './navbarStyle.scss'
import './offcanvasStyle.scss'
import CommonOffcanvas from './CommonOffcanvas'
import { clear_user_dineIn_data } from '../features/dineIn/dineInSlice'
import { reset_takeaway_state } from '../features/takeaway/takeawaySlice'


// import { useAppSelector, useAppDispatch } from '../app/hook'
// import { IRootState } from "../app/Store";

// import { customer_logout } from '../features/customerAuth/customerAuthSlice';
// import { admin_logout_setup } from '../features/admin/adminSlice';
// import { reset_dineInOrder } from '../features/dineIn/dineInSlice';

function TopNav() {
  const dispatch = useAppDispatch();
  // const isAdmin = useAppSelector((state: IRootState) => state.admin.adminIsAuth);
  // const isCustomer = useAppSelector((state: IRootState) => state.customerAuth.customerIsAuth);
  const navigate = useNavigate();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(!show);

  const handleHomeButtonClick = () => {
    dispatch(reset_takeaway_state());
    dispatch(clear_user_dineIn_data()); 
    dispatch(set_customer_mode(null));
    navigate("/");
  }


  return (

    
    <>
      <div className='topNavContainer'>
        <p className='nav-store-name' onClick={()=> handleHomeButtonClick()}>VANÉDIA</p>
        <p className="dropdown-menu-icon" onClick={handleShow}><FontAwesomeIcon icon={faBars} /></p>
      </div>

      {show && <CommonOffcanvas closeCanvas={handleClose}/>}
      {/* {(show && isAdmin) &&  <AdminOffcanvas closeCanvas={handleClose}/>}
      {(show && isCustomer) && <CustomerOffcanvas closeCanvas={handleClose}/>} */}

    </>
    
  )
}

export default React.memo(TopNav)