import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type foodOrder = {
    transactionNum: string,
    id: number,
    foodName: string,
    foodQuantity: number,
    amount: number,
    tableNum: number
}

// let addNewFoodObj: foodOrderItem = {
//     transactionNum: transactionNum,
//     id: id,
//     foodName: foodName,
//     foodQuantity: 1,
//     tableNum: tableNum as number,
//     amount: unitPrice
//   }

type foodOrderForAdd = {
    transactionNum: string,
    foodId: number,
    foodName: string,
    orderQty: number,
    amount: number,
    tableNo: number
}


type orderSummaryItem = {
    id: number;
    foodName: string;
    total_qty: number;
    total_amount: number;
}

type DineInState = {
    dineInTransactionNumber: null|string,
    tableNumber: null|number,
    dineInStatus: null| "active" | "completed",
    dineInOrder: foodOrder[],
    orderSummary: orderSummaryItem[],
    userAction: string
}

const initialState: DineInState = {
    dineInTransactionNumber: null,
    tableNumber: null,
    dineInStatus: null,
    dineInOrder: [],
    orderSummary: [],
    userAction: 'home'
};

export const dineInSlice = createSlice({
    name: "dineIn",
    initialState,
    reducers: {
        set_table_num: (state: DineInState, action: PayloadAction<number>)=> {
            state.tableNumber = action.payload;
        },
        set_transaction_num : (state: DineInState, action: PayloadAction<string>)=> {
            state.dineInTransactionNumber = action.payload;
            state.dineInStatus = "active"
        },
        end_dineIn : (state: DineInState, action: PayloadAction<string>)=> {
            if (state.dineInTransactionNumber === action.payload){
                state.dineInTransactionNumber = null;
                state.dineInStatus = "completed";
                state.tableNumber = null
            }
        },
        add_food: (state: DineInState, action: PayloadAction<foodOrder>)=> {
            if(state.dineInTransactionNumber === null){
                return
            }

            if((state.dineInOrder).length === 0){
                let existingOrderCopy = [...state.dineInOrder];
                existingOrderCopy.push(
                    {...action.payload}
                )

                return {
                    ...state, dineInOrder: existingOrderCopy
                }
            } else {
                const checkOrder = action.payload.id;
                let existingOrderCopy = [...(state.dineInOrder)];
                let checkExistingOrder = existingOrderCopy.some(obj => obj.id === checkOrder);

                if (checkExistingOrder){
                    existingOrderCopy = existingOrderCopy.map((item) => {
                        if(item.id === (action.payload).id){
                            const newQty = item.foodQuantity + (action.payload).foodQuantity;
                            const newPrice = item.amount + (action.payload).amount
                            return {...item, foodQuantity: newQty, amount: newPrice}
                        } 
                        return item
                    })
                    return {
                        ...state, dineInOrder: existingOrderCopy
                    }

                } else { 
                    //if none of the food exist in local record
                    existingOrderCopy.push(
                        {...action.payload}
                    )

                    return {
                        ...state, dineInOrder: existingOrderCopy
                    }
                }
            }
        },
        remove_food: (state: DineInState, action: PayloadAction<foodOrder>) => {
            if(state.dineInTransactionNumber === null){
                return
            }

            if ((state.dineInOrder).length > 0){
                const copy = [...state.dineInOrder];
                let removeItem = copy.map((item) => {
                    if(item.id === (action.payload).id){
                        if (item.amount > 0){
                            const newQty = item.foodQuantity - (action.payload).foodQuantity;
                            const newPrice = item.amount - (action.payload).amount

                            return {...item, foodQuantity: newQty, amount: newPrice}
                        } 
                    }
                   return item
                })
                
                const filteredOrder = removeItem.filter((item) => item.foodQuantity > 0)

                return {
                    ...state, dineInOrder: filteredOrder
                }
            } 
            return state
        },
        reset_dineInOrder: (state: DineInState) => {
            return {
                ...state, dineInOrder: []
            }
        },
        save_order_summary: (state: DineInState, action: PayloadAction<orderSummaryItem[]>) => {
            let orderSummaryCopy = [...(action.payload)];     
            return {
                ...state, orderSummary: orderSummaryCopy
            }
        },
        set_user_action: (state: DineInState, action: PayloadAction<string>) => {
            return {
                ...state, userAction: action.payload
            }
        },
        clear_user_dineIn_data: (state: DineInState) => {
            return {
                dineInTransactionNumber: null,
                tableNumber: null,
                dineInStatus: null,
                dineInOrder: [],
                orderSummary: [],
                userAction: 'home'
            }
        }

    }
});


export const { add_food, remove_food, set_transaction_num, end_dineIn, set_table_num, 
    reset_dineInOrder, save_order_summary, set_user_action, clear_user_dineIn_data} = dineInSlice.actions;
export default dineInSlice.reducer;

// export const dineInSlice = createSlice({
//     name: "dineIn",
//     initialState,
//     reducers: {
//         set_table_num: (state: DineInState, action: PayloadAction<number>)=> {
//             state.tableNumber = action.payload;
//         },
//         set_transaction_num : (state: DineInState, action: PayloadAction<string>)=> {
//             state.dineInTransactionNumber = action.payload;
//             state.dineInStatus = "active"
//         },
//         end_dineIn : (state: DineInState, action: PayloadAction<string>)=> {
//             if (state.dineInTransactionNumber === action.payload){
//                 state.dineInTransactionNumber = null;
//                 state.dineInStatus = "completed";
//                 state.tableNumber = null
//             }
//         },
//         add_food: (state: DineInState, action: PayloadAction<foodOrder>)=> {
//             if(state.dineInTransactionNumber === null){
//                 return
//             }

//             if((state.dineInOrder).length === 0){
//                 let existingOrderCopy = [...state.dineInOrder];
//                 existingOrderCopy.push(
//                     {...action.payload}
//                 )

//                 return {
//                     ...state, dineInOrder: existingOrderCopy
//                 }
//             } else {
//                 const checkOrder = action.payload.id;
//                 let existingOrderCopy = [...(state.dineInOrder)];
//                 let checkExistingOrder = existingOrderCopy.some(obj => obj.id === checkOrder);

//                 if (checkExistingOrder){
//                     existingOrderCopy = existingOrderCopy.map((item) => {
//                         if(item.id === (action.payload).id){
//                             const newQty = item.foodQuantity + (action.payload).foodQuantity;
//                             const newPrice = item.amount + (action.payload).amount
//                             return {...item, foodQuantity: newQty, amount: newPrice}
//                         } 
//                         return item
//                     })
//                     return {
//                         ...state, dineInOrder: existingOrderCopy
//                     }

//                 } else { 
//                     //if none of the food exist in local record
//                     existingOrderCopy.push(
//                         {...action.payload}
//                     )

//                     return {
//                         ...state, dineInOrder: existingOrderCopy
//                     }
//                 }
//             }
//         },
//         remove_food: (state: DineInState, action: PayloadAction<number>) => {
//             if(state.dineInTransactionNumber === null){
//                 return
//             }

//             if ((state.dineInOrder).length > 0){
//                 const copy = [...state.dineInOrder];
//                 let removeItem = copy.map((item) => {
//                     if(item.id === action.payload){
//                         if (item.amount > 0){
//                             return {...item, foodQuantity: item.foodQuantity - 1}
//                         } 
//                     }
//                    return item
//                 })

//                 const filteredOrder = removeItem.filter((item) => item.foodQuantity > 0)

//                 return {
//                     ...state, dineInOrder: filteredOrder
//                 }
//             } 
//             return state
//         },
//         reset_dineInOrder: (state: DineInState) => {
//             return {
//                 ...state, dineInOrder: []
//             }
//         },
//         save_order_summary: (state: DineInState, action: PayloadAction<orderSummaryItem[]>) => {
//             let orderSummaryCopy = [...(action.payload)];
//             console.log(orderSummaryCopy)        
//             return {
//                 ...state, orderSummary: orderSummaryCopy
//             }
//         }

//     }
// });


// export const { add_food, remove_food, set_transaction_num, end_dineIn, set_table_num, reset_dineInOrder, save_order_summary } = dineInSlice.actions;
// export default dineInSlice.reducer;