import React, { useState } from 'react'
import TableQRCode from './TableQRCode';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import withReactContent from 'sweetalert2-react-content'

import QRCode from "react-qr-code";

type ViewQRCodeButtonProps = {
    table_num: number,
    transaction_num: string
}

function ViewQRCodeButton({table_num, transaction_num}: ViewQRCodeButtonProps) {
    const url = `${process.env.REACT_APP_FRONTEND_URL}/dineIn/${table_num}/${transaction_num}`

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const MySwal = withReactContent(Swal);

    // const handleClickQRButton = () => {

    //     Swal.fire(
    //         `<div className='qr-code-title'>QR Code for Table{table_num}</div>`
    //         `<div className="table-qr-code" style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
    //             <QRCode
    //             size={256}
    //             style={{ height: "auto", maxWidth: "100%", width: "100%" }}
    //             value={url}
    //             viewBox={`0 0 256 256`}/>`
    //         </div>`
    //     )
    // }

    return (
        <>
            <button className="qr-code-button" onClick={handleShow}>
                QR Code
            </button>

            {show && <TableQRCode table_num={table_num} transaction_num={transaction_num} onHide={handleClose}/>}
        </>
    )
}

export default React.memo(ViewQRCodeButton)