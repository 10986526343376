import React from 'react'
import { useAppSelector } from "../../app/hook";
import { IRootState } from '../../app/Store';
import TakeawayFoodItem from './TakeawayFoodItem';

type TakeawayMenuByCategoryProps = {
    userChoice: string
}

interface menuItemsFromDb {
    id: number,
    foodName: string,
    imageUrl: string,
    unitPrice: number,
    stockStatus: boolean,
    foodTypeName: string, 
    quantity: number
}

function TakeawayMenuByCategory({userChoice}: TakeawayMenuByCategoryProps) {
    let initMenuList = (useAppSelector((state: IRootState) => state.menu.allItems)) as menuItemsFromDb[];
    let takeawayMenuByType = initMenuList.filter((item) => item.foodTypeName === userChoice);

    return (
        <div className='food-list-container'>
            {takeawayMenuByType.map((item)=> <TakeawayFoodItem key={item.id} foodCategory={item.foodTypeName} id={item.id} 
                foodName={item.foodName} foodQty={item.quantity} imageUrl={item.imageUrl} unitPrice={item.unitPrice}/>)}
        </div>
    )
}

export default React.memo(TakeawayMenuByCategory)