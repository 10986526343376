import React from 'react'
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { adminChangeTakeawayCompleteState } from '../adminAPI';

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import "../adminStyle.scss";

type TakeawayToCompleteButtonProps = {
    id: number
}


function TakeawayToCompleteButton({id}: TakeawayToCompleteButtonProps) {
    const queryClient = useQueryClient();
    const MySwal = withReactContent(Swal);

    const onToComplete = useMutation(
        async(data: {id: number}) => 
        adminChangeTakeawayCompleteState(data.id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(["adminTakeawayOrderSummary"])
      
                Swal.fire({
                    icon: 'success',
                    title: 'Updated takeaway order status',
                    showConfirmButton: false,
                    timer: 1500
                })
            }, 
            onError: () => {
                Swal.fire({
                    icon: 'error',
                    title: 'Unable to Update Takeaway Order',
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        }
    )

    return (
        <div onClick={()=> {onToComplete.mutate({id: id})}}
        className='admin-takeaway-complete-button'>
            Complete
        </div>
    )
}

export default React.memo(TakeawayToCompleteButton)