import React from "react";
import { IRootState } from "../../app/Store";
import { useAppSelector} from "../../app/hook";
import FoodItem from "./FoodItem";
import './foodStyle.scss'

function FoodList() {
  let initMenuList = useAppSelector((state: IRootState) => state.menu.allItems);

  return (
   <div className="food-list-container">
    {initMenuList?.map((item) => 
            <FoodItem key={item.id} id={item.id} 
            foodName={item.foodName} foodCategory={item.foodTypeName} 
            imageUrl={item.imageUrl} unitPrice={item.unitPrice} foodQty={item.quantity}/>
        )}
   </div>
  )
}

export default React.memo(FoodList)