import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { set_table_num, set_transaction_num } from "./dineInSlice";
import { set_customer_mode } from "../customerAuth/customerAuthSlice";
import { useAppDispatch } from "../../app/hook";
import { checkTableIdAndTransId } from "./dineInAPI";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { reset_takeaway_state } from "../takeaway/takeawaySlice";

import HomeIntro from "../../pages/HomeIntro";

import "../../App.scss";
import "./dineInStyle.scss";

import "../../App.scss";
import "./dineInStyle.scss";

function DineIn() {
  const { tableNum, transactionNum } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(reset_takeaway_state())
    
    let timer: any;

    const checkValid = async () => {
      const checkResult = await checkTableIdAndTransId(
        parseInt(tableNum as string),
        transactionNum as string
      );
      if (checkResult === false) {
        timer = setTimeout(() => {
          dispatch(set_customer_mode(null));
          navigate("/invalidToken");
        }, 1000);
      }
      // clearTimeout(timer);
    };

    checkValid();
  }, []);

  const [animation, setAnimation] = useState<boolean>(true);

  useEffect(()=> {
    const timer = setTimeout(() => {
        setAnimation(false)
    }, 4500)

    return ()=> {
        clearTimeout(timer)
    }
  }, [])

  useEffect(() => {
    dispatch(set_transaction_num(transactionNum as string));
    dispatch(set_table_num(parseInt(tableNum as string)));
    dispatch(set_customer_mode("dineIn"));

    return () => {};
  }, [tableNum, transactionNum, dispatch]);

  return (
    <>
      {animation && <HomeIntro/>}
      
      <div className="dineInRedirectContainer">
      <div className="home-title-noUnderline">VANÉDIA</div>
      <Link style={{ textDecoration: "none" }} to="/dineIn">
        <div className="dineInButtonContainer">
          <img src="../../dish1.png" alt="dish1" />
          <p>dine-in</p>
        </div>
      </Link>
    </div>
    </>
    
  );
}

export default React.memo(DineIn);