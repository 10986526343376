import React, {useEffect} from 'react'
import { useAppDispatch, useAppSelector } from '../app/hook'
import { set_customer_mode } from '../features/customerAuth/customerAuthSlice'
import { IRootState } from '../app/Store'

import { clear_user_dineIn_data } from '../features/dineIn/dineInSlice'

import TakeawayMenuPage from '../features/takeaway/TakeawayMenuPage'
import TakeawayShoppingBag from '../features/takeaway/TakeawayShoppingBag'
import TakeawayInfoPayment from '../features/takeaway/TakeawayInfoPayment'
import TakeawayLogin from '../features/takeaway/TakeawayLogin'
import MyTakeawayOrder from '../features/takeaway/MyTakeawayOrder'

import TopNav from '../nav/TopNav'
import TakeawayBottomNavbar from '../nav/TakeawayBottomNavbar'

function TakeawayHome() {
    // const isAdmin = localStorage.getItem("adminToken");
    // if(isAdmin){
    //     localStorage.removeItem("adminToken");
    const dispatch = useAppDispatch();
    // }
    const takeawayUserAction = useAppSelector((state: IRootState)=> state.takeawayStatus.takeawayUserAction);
    

    useEffect(()=> {
        dispatch(clear_user_dineIn_data())
        dispatch(set_customer_mode('takeaway'))
    })

    return (
        <>
            {<TopNav/>}

            {takeawayUserAction === 'menu' && <TakeawayMenuPage/>}
            {takeawayUserAction === 'shoppingBag' && <TakeawayShoppingBag/>}
            {takeawayUserAction === 'userAuth' && <TakeawayLogin />}
            {takeawayUserAction === 'paymentAndInfo' && <TakeawayInfoPayment/>}
            {takeawayUserAction === "myOrder" && <MyTakeawayOrder/>}
            <TakeawayBottomNavbar />
        </>
        
    )
}

export default React.memo(TakeawayHome)