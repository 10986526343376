import React, {useState} from 'react'
import { set_admin_current_action } from './adminSlice'
import NewOrderModal from './NewOrderModal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import { useMutation, useQueryClient } from '@tanstack/react-query';



import './adminStyle.scss';
import { createDineInNewTransaction } from './adminAPI';

type NewButtonProps = {
  tableNum: number,
  dispatch: any,
}

function NewOrderButton({tableNum, dispatch}: NewButtonProps) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [confirmNewOrder, setConfirmNewOrder] = useState<boolean>(false);

  const handleNewOrder = (tableNum: number) => {
    const adminAction = {
      actionType: "newTable",
      actionTableNumber: tableNum
    }
    dispatch(set_admin_current_action(adminAction))
  }

  const MySwal = withReactContent(Swal);
  const queryClient = useQueryClient();

  const confirmNewOrderAlert = async() => {
    Swal.fire({
      title: `Order #${tableNum}: Start new order?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#6fb793',
      cancelButtonColor: '#dfa2b5',
      confirmButtonText: 'Confirm'
    }).then((result) => {
      if (result.isConfirmed) {
        // Swal.fire(
        //   'Updated'
        // )
        setConfirmNewOrder(true);
        return true
      } else {
        return false
      }
    })
  }

  const onAddTable = useMutation(
    async (data: {table_id: number}) => 
    createDineInNewTransaction(data.table_id), 
  {
    onSuccess: () => {
      Swal.fire({
            icon: 'success',
            title: 'Created new order',
            showConfirmButton: false,
            timer: 1500
        })
      dispatch(set_admin_current_action(null))
      queryClient.invalidateQueries(['tableStatus'])
      
    },
    onError: ()=> {
      Swal.fire({
            icon: 'error',
            title: 'Could not create new order',
            showConfirmButton: false,
            timer: 1500
        })
    }
    }
  )

  return (
    <>
      <button className="admin-new-order-button" onClick={()=>{
        handleShow()
      }}>New Order</button>

      {show && <NewOrderModal tableNum={tableNum} 
        handleNewOrder={()=> handleNewOrder(tableNum)} 
        handleClose={handleClose}
        dispatch={dispatch}
      />}

      {/* {confirmNewOrder &&  onAddTable.mutate({table_id: tableNum})} */}
    </>
    
  )
}

export default React.memo(NewOrderButton)