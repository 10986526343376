import React from 'react'
import { add_food, remove_food } from '../dineIn/dineInSlice';
import { menu_handle_add, menu_handle_minus } from '../menu/menuSlice';
import { IRootState } from "../../app/Store";
import { useAppSelector } from "../../app/hook";

import './shoppingBagStyle.scss';

type ShoppingBagItemProps = {
    id: number,
    itemQty: number,
    itemName: string,
    itemAmount: number,
    dispatch: any
}

type foodOrderItem = {
    transactionNum: string,
    id: number,
    foodName: string,
    foodQuantity: number,
    amount: number,
    tableNum: number,
}

function ShoppingBagItem({id, itemQty, itemName, itemAmount, dispatch}:ShoppingBagItemProps) {
    const transactionNum = (useAppSelector((state: IRootState) => state.dineIn.dineInTransactionNumber) as string);
    const tableNum = (useAppSelector((state: IRootState)=> state.dineIn.tableNumber));

    const handleAddQty = () => {

        let addNewFoodObj: foodOrderItem = {
            transactionNum: transactionNum,
            id: id,
            foodName: itemName,
            foodQuantity: 1,
            amount: (itemAmount / (itemQty as number)),
            tableNum: tableNum as number,
        }

        dispatch(add_food(addNewFoodObj))
        dispatch(menu_handle_add(id));
    }
    
    const handleMinusQty = () => {
        if (itemQty > 0){

            let minusNewFoodObj: foodOrderItem = {
                transactionNum: transactionNum,
                id: id,
                foodName: itemName,
                foodQuantity: 1,
                amount: (itemAmount / (itemQty as number)),
                tableNum: tableNum as number,
            }

            dispatch(remove_food(minusNewFoodObj))
            dispatch(menu_handle_minus(id));
        }
    }


    return (

            <div className='shopping-bag-single-item-container'>

                <p className='shopping-bag-dish-name'>{itemName}</p>

                <div className='shopping-bag-item-qty-amount-container'>
                    <div className='shopping-bag-item-qty-container'>
                        <div className="shopping-bag-food-qty-minus-button" onClick={handleMinusQty}>-</div>
                        <div className='food-qty'>{itemQty}</div>
                        <div className="food-qty-add-button" onClick={handleAddQty}>+</div>
                    </div>
                    
                    <p className='shopping-bag-item-price'>${itemAmount}</p>
                </div>
            </div>
    
    )
}

export default React.memo(ShoppingBagItem)