import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

type takeawayFoodOrder = {
    transactionNum: string,
    id: number,
    foodName: string,
    foodQuantity: number,
    amount: number,
}

type takeawayFoodOrderForOps = {
    id: number,
    foodName: string,
    foodQuantity: number,
    amount: number,
}

type takeawayOrderSummaryItem = {
    id: number;
    foodName: string;
    total_qty: number;
    total_amount: number;
}

type takeawayState = {
    takeawayTransactionNum: null|string,
    takeawayOrder: takeawayFoodOrderForOps[],
    takeawayOrderSummary: takeawayOrderSummaryItem[],
    takeawayUserAction: string,
    takeawayStatus: string | null
}

const initialState: takeawayState = {
    takeawayTransactionNum: null,
    takeawayOrder: [],
    takeawayOrderSummary: [],
    takeawayUserAction: 'menu',
    takeawayStatus: null
}

export const takeawaySlice = createSlice({
    name: "customerTakeaway",
    initialState,
    reducers: {
        set_takeaway_trans_num: (state: takeawayState, action: PayloadAction<string|null>) => {
            state.takeawayTransactionNum = action.payload
            state.takeawayStatus = "active"

            return state
        },
        takeaway_add_food: (state: takeawayState, action: PayloadAction<takeawayFoodOrderForOps>) => {

            if((state.takeawayOrder).length === 0){
                let existingOrderCopy = [...state.takeawayOrder];
                existingOrderCopy.push(
                    {...action.payload}
                )

                return {
                    ...state, takeawayOrder: existingOrderCopy
                }
            } else {
                const checkOrder = action.payload.id;
                let existingOrderCopy = [...(state.takeawayOrder)];
                let checkExistingOrder = existingOrderCopy.some(obj => obj.id === checkOrder);

                if (checkExistingOrder){
                    existingOrderCopy = existingOrderCopy.map((item) => {
                        if(item.id === (action.payload).id){
                            const newQty = item.foodQuantity + (action.payload).foodQuantity;
                            const newPrice = item.amount + (action.payload).amount
                            return {...item, foodQuantity: newQty, amount: newPrice}
                        } 
                        return item
                    })
                    return {
                        ...state, takeawayOrder: existingOrderCopy
                    }
                } else { 
                    existingOrderCopy.push(
                        {...action.payload}
                    )

                    return {
                        ...state, takeawayOrder: existingOrderCopy
                    }
                }

            }
        },
        takeaway_remove_food: (state: takeawayState, action: PayloadAction<takeawayFoodOrderForOps>) => {
            // if(state.takeawayTransactionNum === null){
            //     return
            // }
            if ((state.takeawayOrder).length > 0){
                const copy = [...state.takeawayOrder];
                let removeItem = copy.map((item) => {
                    if(item.id === (action.payload).id){
                        if (item.amount > 0){
                            const newQty = item.foodQuantity - (action.payload).foodQuantity;
                            const newPrice = item.amount - (action.payload).amount

                            return {...item, foodQuantity: newQty, amount: newPrice}
                        } 
                    }
                    return item
                })

                const filteredOrder = removeItem.filter((item) => item.foodQuantity > 0)

                return {
                    ...state, takeawayOrder: filteredOrder
                }
            } 
            return state
        },
        reset_takeawayOrder: (state: takeawayState) => {
            return {
                ...state, takeawayOrder: []
            }
        },
        save_takeaway_order_summary: (state: takeawayState, action: PayloadAction<takeawayOrderSummaryItem[]>) => {
            let orderSummaryCopy = [...action.payload];

            return {
                ...state, takeawayOrderSummary: orderSummaryCopy
            }
        },
        set_takeaway_user_action: (state: takeawayState, action: PayloadAction<string>) => {
            return {
                ...state, takeawayUserAction: action.payload
            }
        },
        end_takeaway : (state: takeawayState)=> {
                state.takeawayTransactionNum = null;
                state.takeawayStatus = "completed";
                return state
        },
        reset_takeaway_state: (state: takeawayState) => {

            return {
                ...state, 
                takeawayTransactionNum: null,
                takeawayOrder: [],
                takeawayOrderSummary: [],
                takeawayUserAction: 'menu',
                takeawayStatus: null
            }
        }

    }
})

export const {set_takeaway_trans_num, takeaway_add_food, takeaway_remove_food, 
    reset_takeawayOrder, save_takeaway_order_summary, set_takeaway_user_action, end_takeaway, reset_takeaway_state} = takeawaySlice.actions;

export default takeawaySlice.reducer;
