import React from "react";
import { useAppDispatch, useAppSelector } from "../../app/hook";
// import { useMutation, useQueryClient } from '@tanstack/react-query';
// import { customerGetOrderSummary } from "./takeawayAPI";
import { set_takeaway_user_action } from "./takeawaySlice";

import "./takeawayStyle.scss";
import { IRootState } from "../../app/Store";
import { useCustomerTakeawayOrderSummary } from "./takeawayAPI";
import MyTakeawayOrderItem from "./MyTakeawayOrderItem";
import "./takeawayStyle.scss";

type CustomerTakeawayOrderRecord = {
  created: Date;
  order_status: string;
  total_amount: number;
  id: number;
};

function MyTakeawayOrder() {
  const isCustomer = useAppSelector(
    (state: IRootState) => state.customerAuth.customerIsAuth
  );

  let record: CustomerTakeawayOrderRecord[] =
    useCustomerTakeawayOrderSummary();

    record = record.sort((a,b) => {
      const orderStatus = ["PREPARING", "COMPLETED"];
      return orderStatus.indexOf(a.order_status) - orderStatus.indexOf(b.order_status);
    });

  const dispatch = useAppDispatch();



  if (!isCustomer) {
    dispatch(set_takeaway_user_action("userAuth"));
  }

  return (
    <>
      <div className="all-takeaway-order-page-container">
        <div className="all-takeaway-order-record-header">my order</div>

            <div className='all-takeaway-order-record-container'>
                {isCustomer && (record.map(item => 
                <MyTakeawayOrderItem created={item.created} order_status={item.order_status}
                    total_amount={item.total_amount} id={item.id} key={item.id}
                />))}
            </div>
        
        </div>
    </>
  );
}

export default React.memo(MyTakeawayOrder);
