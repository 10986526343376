import { useQuery } from "@tanstack/react-query";

interface IAdminLoginInfo {
  shopName: string;
  password: string;
}

export async function adminLogin(input: IAdminLoginInfo) {
  try {
    const adminInfo = {
      shopName: input.shopName,
      password: input.password,
    };

    const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/loginadmin`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(adminInfo),
    });

    const result = await res.json();
    if (res.status === 200) {
      localStorage.setItem("adminToken", result.token);

      return result.token;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export async function createDineInNewTransaction(table_id: number) {
  try {
    const token = localStorage.getItem("adminToken");
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/admin/order/${table_id}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await res.json();
    if (res.status === 200) {
      return result;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export function useAdminTableStatus() {
  const token = localStorage.getItem("adminToken");

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["tableStatus"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/tablestatus`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            // "Content-Type": "application/x-www-form-urlencoded"
          },
          // credentials: "include"
        }
      );

      const result = await res.json();
      return result;
    },
  });

  if (isLoading || isFetching || error || !data) {
    return [];
  }

  return data;
}

type AllTakeawayOrders = {
  id: number;
  orderStatus: string;
  contactPhone: string;
  uuid: string;
};

export function useAdminAllTakeawayOrders() {
  const token = localStorage.getItem("adminToken");

  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["adminTakeawayOrderSummary"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/admin/takeaway/orderSummaryV2`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const result = await res.json();
      return result as AllTakeawayOrders[];
    },
  });

  if (isLoading || isFetching || error || !data) {
    return [];
  }

  return data;
}

export async function adminChangeTakeawayPickUpState(order_id: number) {
  try {
    const token = localStorage.getItem("adminToken");

    if (!token || order_id < 1) {
      return false;
    }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/admin/takeaway/picknow/${order_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await res.json();
    return result;
  } catch (err) {
    return false;
  }
}

export async function adminChangeTakeawayCompleteState(order_id: number) {
  try {
    const token = localStorage.getItem("adminToken");

    if (!token || order_id < 1) {
      return false;
    }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/admin/takeaway/completed/${order_id}`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const result = await res.json();
    return result;
  } catch (err) {
    return false;
  }
}


export function useAdminCheckTableOrder(transaction_num: string){
    const {isLoading, error, data, isFetching} = useQuery({
        queryKey: ["specificTableStatus"],
        queryFn: async ()=> {
            const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/admin/orderv2/${transaction_num}`, {
                headers: {
                    "Authorization":`Bearer ${localStorage.getItem('adminToken')}` 
                },          
            });
            const result = await res.json();
            // console.log(result)
            return result
        }
    });


  if (isLoading || isFetching || error || !data) {
    return [];
  }
  return data;
}

interface IOrderPaymentInfo {
  paymentMethod: string;
  transactionUuid: string;
  tableNo: number;
}

export async function adminConfirmPaymentMethod(input: IOrderPaymentInfo) {
  try {
    const { paymentMethod, transactionUuid, tableNo } = input;

    const paymentMethodObj = {
      paymentMethod: "CASH",
      tableNo: 3,
    };

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/admin/checkout/${transactionUuid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        // credentials: 'include',
        body: JSON.stringify(paymentMethodObj),
      }
    );

    const result = await res.json();

    if (res.status === 200) {
      return true;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

interface IOrderAmountInfo {
  customerId: number;
  shopId: number;
  totalAmount: number;
  transactionsUuid: string;
}

export async function adminConfirmOrderAmount(input: IOrderAmountInfo) {
  try {
    const { transactionsUuid } = input;
    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/admin/checkout/${transactionsUuid}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("adminToken")}`,
        },
        body: JSON.stringify(input),
      }
    );

    const result = await res.json();
    if (res.status === 200) {
      return result;
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}



// export async function createDineInTransactionNum(uuid: string, table_id: number){
//     try {
//         const newTable = {
//             uuid: uuid
//         }
//         const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/admin/order/${table_id}`, {
//             method: "POST",
//             headers: {
//                 "Content-Type":'application/json',
//                 "Authorization":`Bearer ${localStorage.getItem('adminToken')}`
//             },
//             body: JSON.stringify(newTable)
//         })

//         const result = await res.json();
//         if (res.status === 200){
//             return result;
//         } else {
//             return false
//         }
//     } catch (err){
//         return false
//     }

// }

// interface IOrderInstoreData {
//     transaction_uuid: string,
//     total_amount: number
// }

// export async function adminConfirmOrderInstore(input: IOrderInstoreData){
//     const {transaction_uuid} = input;
//     const res = await fetch(`${process.env.REACT_APP_BACKEND_API}/admin/checkout/${transaction_uuid}`, {
//         method: "POST",
//         headers: {
//             "Content-Type":'application/json',
//             "Authorization":`Bearer ${localStorage.getItem('admin_token')}`
//         },
//         body: JSON.stringify(input)
//     })

//     const result = await res.json();

//     if(res.status===200){
//         return result
//     } else {
//         return false
//     }
// }
