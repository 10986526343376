import React from 'react';
import TakeawayOrderContactForm from './TakeawayOrderContactForm';
// import StripePayment from './takeawayPayment/StripePayment';
import './takeawayStyle.scss';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`);

function TakeawayInfoPayment() {

  return (
    <>
      <div className='takeaway-info-payment-container'>
        <div className='takeaway-info-payment-section-title'>info & payment</div>
        
        <Elements stripe={stripePromise}>
          <TakeawayOrderContactForm/>
        </Elements>
        {/*<StripePayment/> */}

        {/* <div className='takeaway-info-payment-button-container'>
          <div>cancel</div>
          <div>submit order</div>
        </div> */}
      </div>
    </>
  )
}

export default React.memo(TakeawayInfoPayment)