import { configureStore } from "@reduxjs/toolkit";
import dineInSlice from "../features/dineIn/dineInSlice";
import customerAuthSlice from "../features/customerAuth/customerAuthSlice";
import menuSlice from "../features/menu/menuSlice";
import adminSlice from "../features/admin/adminSlice"
import tableStatusSlice from "../features/admin/tableStatusSlice";
import takeawaySlice from "../features/takeaway/takeawaySlice";

export const  store = configureStore({
    reducer: {
      dineIn: dineInSlice,
      customerAuth: customerAuthSlice,
      menu: menuSlice,
      admin: adminSlice,
      tableStatus: tableStatusSlice,
      takeawayStatus: takeawaySlice
    }
})

export type IRootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
