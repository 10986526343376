import React, {useState} from 'react'
import LoginForm from '../features/customerAuth/LoginForm'
import RegisterForm from '../features/customerAuth/RegisterForm'
import './loginPageStyle.scss';

function Login() {

    const [userAction, setUserAction] = useState<"login"|"register"|"complete">("login");
    const handleChangeAction = (input: "login"|"register"|"complete") => {
      setUserAction(input);
    }

    const handleFormSubmitState = () => {
    setUserAction("complete");
  }
  return (
    <div className='loginPageContainer'>
        
        {userAction === "login" && <LoginForm formSubmitFn={handleFormSubmitState} changeActionFn={()=> handleChangeAction("register")}/>}
        {userAction === "register" && <RegisterForm formSubmitFn={handleFormSubmitState} changeActionFn={()=> handleChangeAction("login")}/>}
    </div>
  )
}

export default React.memo(Login)