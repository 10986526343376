import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import "./homeStyle.scss"

import HomeIntro from './HomeIntro';

function HomeDirect() {
    const [animation, setAnimation] = useState<boolean>(true);

    useEffect(()=> {
        const timer = setTimeout(() => {
            setAnimation(false)
        }, 5000)

        return ()=> {
            clearTimeout(timer)
        }
    }, [])

    return (
        <>
            {animation && <HomeIntro/>}

            <div className='homeDirectContainer'>  
                <div className='home-title-noUnderline'>VANÉDIA</div>
                
                <Link style={{textDecoration: 'none'}} to="/dineIn">
                    <div className='dineInButtonContainer'>
                        <img src="dish1.png" alt="dish1"/>
                        <p>dine-in</p>
                    </div>
                </Link> 

                <Link style={{textDecoration: 'none'}} to="/takeaway">
                    <div className='takeawayButtonContainer'>
                        <p>takeaway</p>
                        <img src="dish2.png" alt="dish1"/>
                    </div>
                </Link>  
            </div>
        </>
    )
}

export default React.memo(HomeDirect)