import React from 'react'
import { useAppSelector, useAppDispatch } from '../../app/hook'
import { IRootState } from '../../app/Store'

import TakeawayShoppingBagItem from './TakeawayShoppingBagItem'
import TakeawayLoginModalButton from './TakeawayLoginModalButton'
import { set_takeaway_user_action } from './takeawaySlice'

import Swal from 'sweetalert2/dist/sweetalert2.js';
import withReactContent from 'sweetalert2-react-content'

function TakeawayShoppingBag() {
    const userLoginStatus = useAppSelector((state: IRootState) => state.customerAuth.customerIsAuth);
    const dispatch = useAppDispatch();
    let takeawayLocalOrder = useAppSelector((state: IRootState)=> state.takeawayStatus.takeawayOrder);
    let takeawayLocalOrderAmount = takeawayLocalOrder.reduce((acc, curr) => {
        return acc + curr.amount
    },0)

    let takeawayLocalOrderQty = takeawayLocalOrder.reduce((acc, curr)=> {
        return acc + curr.foodQuantity
    },0)

    const handleProceedButtonClick = () => {
        if(takeawayLocalOrder.length < 1) {
            Swal.fire({
                icon: 'info',
                title: 'Please add items to shopping cart first',
                showConfirmButton: false,
                timer: 1500
            })
        } else {
            dispatch(set_takeaway_user_action('paymentAndInfo'))
        }
        
    }

    return (
        <>
            <div className='takeaway-shopping-bag-container'>
                <div className='takeaway-section-title'>shopping bag</div>

                <div className='shopping-bag-item-container'>
                    {takeawayLocalOrder.map((item)=> 
                        <TakeawayShoppingBagItem key={item.id} id={item.id} 
                        itemQty={item.foodQuantity} itemAmount={item.amount} dispatch={dispatch} itemName={item.foodName}/>
                    )}
                </div>
                

                <div className='takeaway-shopping-bag-order-summary'>
                    <div>Sub Total</div>

                    <div className='total-amount-container'>
                        <span>Amount: </span> 
                        <span>${takeawayLocalOrderAmount}</span>
                    </div>
                    
                    <div className='dish-number-container'>
                        <span>Number of Dish: </span>
                        <span>{takeawayLocalOrderQty}</span>
                    </div>

                </div>
                {/* {!userLoginStatus && <LoginSubmitOrderButton/>} */}
                <div className='takeaway-shopping-bag-button-container'>
                    {!userLoginStatus && <TakeawayLoginModalButton/>}
                    {userLoginStatus && <div className="takeaway-next-button" onClick={handleProceedButtonClick}>Proceed Payment</div>}
                </div>
                
            </div>
        </>
    )
}

export default React.memo(TakeawayShoppingBag)