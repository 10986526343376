import { useForm } from "react-hook-form";
import React, { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message"
import {customer_register_setup} from "./customerAuthSlice";
import { useAppDispatch } from "../../app/hook";
import { customerRegister } from "./customerAuthAPI";

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../../pages/loginPageStyle.scss';


type CustomerRegisterInfo = {
    customer_name: string;
    password: string;
    email: string;
    phone: null|number;
    confirmPassword: string;
}

type CustomerRegisterInfoForAPI = {
    customer_name: string;
    password: string;
    email: string;
    phone: null|string;
}


type RegisterFormProps = {
    changeActionFn: (input:string) => void;
    formSubmitFn: () => void;
}

function RegisterForm({changeActionFn, formSubmitFn}: RegisterFormProps) {
    const dispatch = useAppDispatch();

    const { register, handleSubmit, formState: {errors}, watch, reset } = useForm<CustomerRegisterInfo>({
        defaultValues: {
            customer_name: "",
            password: "",
            email: "",
            phone: null,
            confirmPassword: ""
        },
    })

    const customerPhoneNumber = watch("phone");
    const password = watch("password");

    useEffect(()=> {
        let sub = watch((data)=> {
        })

        return () => sub.unsubscribe()
    }, [watch]);

    async function submitRegisterForm(user_input: CustomerRegisterInfo){
        const {customer_name, password, email, phone} = user_input;

        const inputForApi = {
            customer_name: customer_name,
            password: password,
            email: email,
            phone: (phone as number).toString()
        }
        const token = await customerRegister(inputForApi);

        if (token){
            Swal.fire({
                icon: 'success',
                title: 'Successfully registered!',
                showConfirmButton: false,
                timer: 1500
            })
            formSubmitFn();
            dispatch(customer_register_setup((customerPhoneNumber as number).toString()))
            localStorage.setItem("customer_token", token);
            reset();

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Member registration error',
                showConfirmButton: false,
                timer: 1500
            })
        }
        
    }


  return (
    <div className="register-form-container">
        <div className="register-form-title">member registration</div>

        <form onSubmit={handleSubmit(submitRegisterForm)}>
            
                <Form.Group>
                    <div className="register-input-container">
                        <label>name</label>
                        <input {...register("customer_name", {
                            required: "Please fill in customer name.", 
                            minLength: {
                                value: 3,
                                message: "Name must be at least 3 characters.",
                            }
                        })}/>
                    </div>
                    

                    <ErrorMessage errors={errors} name="customer_name"/>
                    <ErrorMessage errors={errors} name="customer_name" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>

                </Form.Group>

                <Form.Group>
                    <div className="register-input-container">
                         <label>phone number</label>
                        <input {...register("phone", {
                            required: "Please fill in phone number.",
                            pattern: {
                                value: /\d+/,
                                message: "This input is number only.",
                            },
                            maxLength: {
                                value: 8,
                                message: "Phone number must be 8 characters long.",
                            },
                            minLength: {
                                value: 8,
                                message: "Phone number must be 8 characters long.",
                            },
                        })}/>
                    </div>
                   
                    <ErrorMessage errors={errors} name="phone"/>

                    <ErrorMessage errors={errors} name="phone" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
                </Form.Group>

                <Form.Group>
                    <div className="register-input-container">
                        <label>email address</label>
                        <input type="email" {...register("email", {
                        required: "Please fill in email address.",
                        pattern: {
                            value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                            message: "Invalid email address",},
                        })}/>
                    </div>
                    
                    <ErrorMessage errors={errors} name="email"/>
                    <ErrorMessage errors={errors} name="email" render={({ messages }) =>
                        messages && Object.entries(messages).map(([type, message]) => (
                        <span style={{ color: "red" }} key={type}>{message}</span>
                    ))}/>
                </Form.Group>


                <Form.Group>
                    <div className="register-input-container">
                        <label>password</label>
                        <input type="password" {...register("password", {required: "Please fill in password", 
                            minLength: {
                                value: 8,
                                message: "Password must be at least 8 characters long.",
                                },
                            pattern: {
                                value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
                                message: 'Password must include both letters and numbers.',
                                },
                                })}/>
                    </div>
                    
                    <ErrorMessage errors={errors} name="password"/>
                    <ErrorMessage errors={errors} name="password" render={({ messages }) =>
                            messages && Object.entries(messages).map(([type, message]) => (
                            <span style={{ color: "red" }} key={type}>{message}</span>
                        ))}/>
                </Form.Group>

                <Form.Group>
                    <div className="register-input-container">
                        <label>confirm password</label>
                        <input type="password" {...register("confirmPassword", {required: "Please confirm your password", 
                                                validate: value => value === password || "Password does not match"
                        })}/>
                    </div>
                    
                    <ErrorMessage errors={errors} name="confirmPassword"/>
                    <ErrorMessage errors={errors} name="confirmPassword" render={({ messages }) =>
                            messages && Object.entries(messages).map(([type, message]) => (
                            <span style={{ color: "red" }} key={type}>{message}</span>
                        ))}/>
                </Form.Group>
            
            <div className="form-button-container">
                <button className="register-form-login-button" onClick={()=> changeActionFn("login")}>login</button>
                <button className="register-form-register-button" type="submit">register</button>
            </div>
            
        </form>
            
    </div>
  )
}

export default React.memo(RegisterForm)