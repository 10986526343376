// import React from 'react'
import { useQuery } from "@tanstack/react-query";

// interface menuItemsFromDb {
//     food_type: string,
//     food_id: string,
//     food_name: string,
//     image_url?: string,
//     unit_price: number,
// }

type foodOrder = {
  transactionNum: string;
  id: number;
  foodName: string;
  foodQuantity: number;
  tableNum: number;
  amount: number;
};

type foodOrderForSubmit = {
  transactionNum: string;
  id: number;
  foodQuantity: number;
  amount: number;
  tableNum: number;
};

interface dineInFoodOrderStatusResult {
  id: number;
  food_name: string;
  image_url?: string;
}

export function useDineInSetupTable(transaction_id: string) {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["transactionInfo"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/menu/dining/${transaction_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
          },
        }
      );
      const result = await res.json();

      return result.data;
      //discuss
    },
  });

  if (isLoading || isFetching || error || !data) {
    return [];
  }

  return data;
}

interface OrderSummaryItemFromDb {
  id: number;
  foodName: string;
  order_qty: number;
  amount: number;
}

export function useDineInTransactionOrderStatus(transaction_id: string) {
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["transactionOrderStatus"],
    queryFn: async () => {
      const res = await fetch(
        `${process.env.REACT_APP_BACKEND_API}/order/${transaction_id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
          },
          // credentials: "include",
        }
      );
      const result = await res.json();

      return result;
      //discuss
    },
  });

  if (isLoading || isFetching || error || !data) {
    return [];
  }

  return data;
}

export async function submitDineInOrder(
  submitOrder: foodOrder[],
  transaction_num: string
) {
  if (submitOrder.length < 1 || !transaction_num) {
    return;
  }

  try {
    const filteredOrders = submitOrder.map(({ foodName, ...rest }) => rest);
    const changeNameOrder = filteredOrders.map((order) => {
      return {
        transactionsUuid: order.transactionNum,
        foodId: order.id,
        orderQty: order.foodQuantity,
        amount: order.amount,
        tableNo: order.tableNum,
      };
    });

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/order/${transaction_num}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("customer_token")}`,
        },
        body: JSON.stringify({ order: changeNameOrder }),
      }
    );

    const result = await res.json();
    return result;
  } catch (err) {
    return { status: "Order submission error" };
  }
}

export async function checkTableIdAndTransId(
  table_num: number,
  transaction_id: string
) {
  try {
    if (!transaction_id || !table_num) {
      return;
    }

    const res = await fetch(
      `${process.env.REACT_APP_BACKEND_API}/check/${table_num}/${transaction_id}`
    );
    // const result = await res.json();

    if (res.status === 400) {
      return false;
    } else {
      return true;
    }
  } catch (err) {
    return false;
  }
}







