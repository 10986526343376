import React from 'react'
import MenuPage from './MenuPage'

import { IRootState } from '../app/Store'
import { useAppSelector, useAppDispatch } from '../app/hook'

import { set_user_action } from '../features/dineIn/dineInSlice'
import ViewOrder from '../features/order/ViewOrder'

import DineInShoppingBag from '../features/dineIn/DineInShoppingBag'
import Login from './Login'
import DineInError from './DineInError';
import TopNav from '../nav/TopNav';
import DineInNavBar from '../nav/DineInNavBar'

function DineInHome() {
    let tableNum = useAppSelector((state: IRootState) => state.dineIn.tableNumber);
    let transactionNum = useAppSelector((state: IRootState) => state.dineIn.dineInTransactionNumber);
    let customerAuthState = useAppSelector((state: IRootState)=> state.customerAuth.customerIsAuth);
    let dineInOrderRecord = useAppSelector((state: IRootState)=> state.dineIn.dineInOrder);
    let userAction = useAppSelector((state: IRootState) => state.dineIn.userAction);
    const dispatch = useAppDispatch();

    // const handleClickOrderButton = () => {
    //     dispatch(set_user_action('viewOrder'))
    // }

    // const handleClickHomeButton = () => {
    //     dispatch(set_user_action('home'))
    // }

    const handleStatusBarClick = () => {
        dispatch(set_user_action('shoppingBag'))
    }

    return (
       <>
            <TopNav/>
            
            <div className='containerWithNav font-style'>  
                {!customerAuthState && <Login/>}
                {customerAuthState && (userAction === 'home' || userAction === null) ? <MenuPage/> : ""}
                {(customerAuthState && userAction === 'viewOrder') && <ViewOrder/>}
                {(customerAuthState && userAction === 'shoppingBag') && <DineInShoppingBag/>}

                {/* <footer>
                    <BottomNavBar clickHome={handleClickHomeButton} clickOrderSummary={handleClickOrderButton}/>
                </footer> */}
                {customerAuthState && <DineInNavBar/>}

            </div>
       </>
        
    )
}

export default DineInHome